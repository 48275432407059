<div
	id="templateComponentTemplates"
	class="faultManagment-container"
	[ngClass]="{
		'displayError': error,
		'faultManagment-container-temp6': showTemplateSix || showTemplateSeven
	}"
>
	<mva10-top-bar
		id="templateComponentMva10TopBar"
		*ngIf="!firstLoading && template?.form?.backButton"
		[leftIcon]="leftIcon"
		[rightIcon]="[rightIcon]"
		(mva10OnClick)="topBarButtonsBehaviour($event.leftIcon.iconTitle)"
		[isLogo]="false"
	>
		<span id="templateComponentMva10TopBarText" *ngIf="!error"> {{ 'faultManagement.title' | translate }} </span>
	</mva10-top-bar>
	<div
		id="templateComponentTemplatesHeader"
		class="header"
		[ngClass]="{
			headerTemp6: showTemplateSix || showTemplateSeven,
			headerTemp8: showTemplateEight || showTemplateNine
		}"
		*ngIf="!firstLoading && !template?.form?.backButton"
	>
		<div id="templateComponentTemplatesHeaderText" class="title" *ngIf="!error">
			<img
				*ngIf="showTemplateEleven"
				id="chatlightIcon"
				[src]="'v10.faultManagement.images.chat_light_theme' | translate | imgFullPath"
				alt="icon"
				class="chat-icon"
			/>
			{{
				showTemplateEleven
					? t11ChatRecordedWCS?.headerText ||
					  ('v10.faultManagement.messagesList.templates.t11chatRecorded.headerText' | translate)
					: ('faultManagement.title' | translate)
			}}
		</div>
		<div
			id="templateComponentTemplatesHeaderClose"
			*ngIf="!showTemplateEleven && !(showTemplateEight || showTemplateNine)"
			(click)="!(showTemplateSix || showTemplateSeven) ? customBack() : closeTemplateSixNavigate()"
		>
			<span class="icon icon-close"> </span>
		</div>
		<div id="templateComponentTemplatesHeaderClose" *ngIf="showTemplateEleven" (click)="failurConfirmBtn()">
			<span id="iconclose" class="icon icon-close"> </span>
		</div>
	</div>
	<div
		id="templateComponentTemplatesSubTitle"
		class="sub-title-main"
		*ngIf="
			!loading &&
			!error &&
			!showTemplateSix &&
			!showTemplateSeven &&
			!showTemplateEight &&
			!showTemplateNine &&
			!showTemplateEleven &&
			!firstLoading &&
			!spinnerWithSteps
		"
	>
		<div id="templateComponentTemplatesSubTitleServiceName">
			{{ 'faultManagement.itemsList.bonitaTemplatesTitle.body' | translate }}
			<ng-container *ngIf="selectedServiceName === ServiceType.Others; else servicename">{{
				' ' + otherServiceName
			}}</ng-container>
			<ng-template #servicename>{{ ' ' + selectedServiceNameText }}</ng-template>
		</div>
		<div
			id="templateComponentTemplatesSubTitleDesc"
			class="desc"
			*ngIf="!serviveTypeTvOrInternet && selectedServiceName !== ServiceType.Others"
		>
			{{ selectedService.id }}
		</div>
		<span id="templateComponentTemplatesSubTitleAddress" class="address" *ngIf="serviveTypeTvOrInternet">
			{{ faultManagementService.selectedSiteAddress | lowercase }}
		</span>
	</div>
	<div
		id="templateComponentTemplatesBody"
		class="body"
		[ngClass]="{
			bodyTemp6: showTemplateSix || showTemplateSeven,
			bodyTemp11: showTemplateEleven
		}"
		*ngIf="!loading && !error && !firstLoading && !spinnerWithSteps"
	>
		<div id="templateComponentTemplateOneAndFour" class="box" *ngIf="showTemplateOne || showTemplateFour">
			<div id="templateComponentTemplateOneAndFourCard" class="template-card">
				<div
					id="{{ 'templateComponentTemplateOneAndFourCardContent' + i }}"
					*ngFor="let item of templateTextWithMedia; let i = index"
					class="add-margin-bottom"
				>
					<div id="{{ 'templateComponentTemplateOneAndFourCardContentTextBox' + i }}" class="iframe" *ngIf="item.value">
						<p
							id="{{ 'templateComponentTemplateOneAndFourCardContentParagraph' + i }}"
							[innerHTML]="item.value | safe : 'html'"
						></p>
					</div>
					<div id="{{ 'templateComponentTemplateOneAndFourCardContentImgBox' + i }}" *ngIf="item.reference">
						<img
							id="{{ 'templateComponentTemplateOneAndFourCardContentImg' + i }}"
							[src]="item.reference"
							class="img-bonita"
						/>
					</div>
				</div>
				<div id="templateComponentTemplateOneAndFourBtns" class="btns">
					<div
						id="{{ 'templateComponentTemplateOneAndFourBtnsContent' + i }}"
						*ngFor="let btn of actions; let i = index"
						[ngClass]="{
							tempOneBtn: showTemplateOne,
							tempFourBtn: showTemplateFour
						}"
					>
						<button
							id="{{ 'templateComponentTemplateOneAndFourButton' + i }}"
							class="confirm button mva10Btn"
							(click)="confirmBtn(btn.label, btn.value)"
						>
							{{ btn.label }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div id="templateComponentTemplateTwo" class="temp2-box" *ngIf="showTemplateTwo">
			<div id="templateComponentTemplateTwoCard" class="template-card margin-bottom">
				<div
					id="{{ 'templateComponentTemplateTwoMedia' + i }}"
					*ngFor="let item of templateTextWithMedia; let i = index"
					class="add-margin-bottom"
				>
					<div id="{{ 'templateComponentTemplateTwoMediaText' + i }}" class="iframe-text" *ngIf="item.value">
						<p
							id="{{ 'templateComponentTemplateTwoMediaParagraph' + i }}"
							[innerHTML]="item?.value | safe : 'html'"
						></p>
					</div>
					<div id="{{ 'templateComponentTemplateTwoMediaImgBox' + i }}" *ngIf="item.reference">
						<img id="{{ 'templateComponentTemplateTwoMediaImg' + i }}" [src]="item.reference" class="img-bonita" />
					</div>
				</div>
			</div>
			<div id="{{ 'templateComponentTemplateTwoAction' + i }}" *ngFor="let label of actions; let i = index">
				<div
					id="{{ 'templateComponentTemplateTwoAction' + i + 'Box' }}"
					class="jumptron-cell"
					(click)="confirmBtn(label.label)"
				>
					<p id="{{ 'templateComponentTemplateTwoAction' + i + 'Paragraph' }}" [innerHTML]="label.label"></p>
					<span id="{{ 'templateComponentTemplateTwoAction' + i + 'Icon' }}" class="icon icon-chevron-right"> </span>
				</div>
			</div>
		</div>
		<div id="templateComponentTemplateThree" class="box" *ngIf="showTemplateThree">
			<form id="templateComponentTemplateThreeForm" [formGroup]="templateForm">
				<div
					id="{{ 'templateComponentTemplateThreeDesc' + i }}"
					*ngFor="let item of templateTextWithMedia; let i = index"
				>
					<div id="{{ 'templateComponentTemplateThreeDescBox' + i }}" class="template-desc" *ngIf="item.value">
						<p
							id="{{ 'templateComponentTemplateThreeDescParagraph' + i }}"
							[innerHTML]="item.value | safe : 'html'"
						></p>
					</div>
					<div id="{{ 'templateComponentTemplateThreeDescImgBox' + i }}" *ngIf="item.reference">
						<img id="{{ 'templateComponentTemplateThreeDescImg' + i }}" [src]="item.reference" class="img-bonita" />
					</div>
				</div>
				<div id="templateComponentTemplateThreeFormInputs" class="form-inputs">
					<div
						id="{{ 'templateComponentTemplateThreeFormInputs' + i }}"
						class="form-input"
						*ngFor="let input of formInputs; let i = index"
					>
						<ng-template
							id="{{ 'templateComponentTemplateThreeFormInputsBox' + i }}"
							[ngIf]="input?.type == 'textarea'"
						>
							<sp-text-area
								id="{{ 'templateComponentTemplateThreeTextArea' + i }}"
								ngDefaultControl
								[id]="input?.id"
								[max]="input?.maxsize"
								[formControlName]="input.name"
								[patternErrorMessage]="input.errorMessage"
								[ngClass]="{ comment: input?.type == 'textarea' }"
								(focusFnEvent)="input.showErrorMsg = false"
								(blurFnEvent)="input.showErrorMsg = true"
								[enforceValidation]="input.showErrorMsg"
								[isRequired]="input?.required"
								[dirtyValidationCheck]="true"
								[pattern]="templatesService.checkValidation(input)"
								[mva10Textbox]="true"
								[placeholder]="input.label"
								[areaRowsNo]="5"
								[textType]="input.type"
								[showError]="
									templateForm.controls[input?.name]?.value?.length < input.minsize &&
									(templateForm.controls[input?.name]?.value?.length >= 1 ||
										templateForm.controls[input?.name]?.dirty) &&
									input.showErrorMsg
								"
							>
							</sp-text-area>
						</ng-template>
						<ng-template
							id="{{ 'templateComponentTemplateThreeFormInputsBox' + i }}"
							[ngIf]="input?.type == 'text' && input.validator === cellDateValidator"
						>
							<mva10-c-date-field
								[minDate]="minDate"
								[maxDate]="maxDate"
								(dateChange)="dateChange(input, $event)"
								(input)="detectInputChangesFromFormControl($event, input.name)"
							>
							</mva10-c-date-field>
						</ng-template>
						<ng-template
							id="{{ 'templateComponentTemplateThreeFormInputsBox' + i }}"
							[ngIf]="
								(input?.type != 'text' || (input?.type == 'text' && input.validator !== cellDateValidator)) &&
								input?.type !== 'textarea'
							"
						>
							<sp-text
								id="{{ 'templateComponentTemplateThreeText' + i }}"
								ngDefaultControl
								(focusFnEvent)="input.showErrorMsg = false"
								(blurFnEvent)="input.showErrorMsg = true"
								(contentChange)="dateValidation(input)"
								[ngClass]="{ comment: input?.type == 'textarea' }"
								id="templateComponentInput?.id"
								[max]="input?.maxsize"
								formControlName="{{ input.name }}"
								[patternErrorMessage]="input.errorMessage ?? templateForm?.controls[input.name].errors | errorMessage"
								[placeholder]="input.label"
								[pattern]="templatesService.checkValidation(input)"
								[enforceValidation]="input.showErrorMsg"
								[dirtyValidationCheck]="true"
								[isRequired]="input?.required"
								[mva10Textbox]="true"
								[redHelperText]="true"
								[showRightIconSuccess]="false"
								[textType]="input?.type"
							>
							</sp-text>
						</ng-template>
					</div>
				</div>
				<div
					id="{{ 'templateComponentTemplateThreeBtnsContent' + i }}"
					*ngFor="let btn of actions; let i = index"
					class="iframe-btn"
				>
					<button
						id="{{ 'templateComponentTemplateThreeButton' + i }}"
						[disabled]="isTextRequired && (!templateForm?.valid || (inputTypeIsDate && !isDateValidDate))"
						[ngClass]="{
							'valid-Form':
								!isTextRequired || (templateForm?.valid && !inputTypeIsDate) || (inputTypeIsDate && isDateValidDate)
						}"
						type="submit"
						class="confirm button mva10Btn"
						(click)="confirmBtn(btn.label)"
					>
						{{ btn.label }}
					</button>
				</div>
			</form>
		</div>
		<div
			id="templateComponentTemplateFive"
			class="template-box"
			*ngIf="showTemplateFive && !showTemplateEight && !showTemplateNine"
		>
			<mva10-c-card id="templateComponentTemplateFiveContainer" [paddingTablet]="'xxl'" [paddingDesktop]="'xxl'">
				<div id="templateComponentTemplateFiveCard" class="temp-card">
					<div
						id="{{ 'templateComponentTemplateFiveCardContainer' + i }}"
						*ngFor="let item of templateTextWithMedia; let i = index"
					>
						<div
							*ngIf="item.reference"
							id="{{ 'templateComponentTemplateFiveCardImgBox' + i }}"
							class="temp-card__icon"
						>
							<img id="{{ 'templateComponentTemplateFiveCardImg' + i }}" [src]="item.reference" alt="topi-icon" />
						</div>
						<div id="{{ 'templateComponentTemplateFiveCardText' + i }}" class="iframe" *ngIf="item.value">
							<p
								id="{{ 'templateComponentTemplateFiveCardParagraph' + i }}"
								[innerHTML]="item.value | safe : 'html'"
								class="temp-card__text"
							></p>
						</div>
					</div>
					<div id="templateComponentTemplateFiveBtns" class="btns">
						<div id="{{ 'templateComponentTemplateFiveBtnsContent' + i }}" *ngFor="let btn of actions; let i = index">
							<mva10-c-button
								id="{{ 'templateComponentTemplateFiveBtnsButton' + i }}"
								[body]="btn.label"
								[styleClass]="i === 1 ? 'mva10-c-button--tertiary' : 'mva10-c-button--primary'"
								(click)="confirmBtnTempFive(i, btn.label, btn.value)"
							>
							</mva10-c-button>
						</div>
					</div>
				</div>
			</mva10-c-card>
		</div>
		<div id="templateComponentTemplateSix" *ngIf="showTemplateSix" class="temp6-container">
			<div
				id="templateComponentTemplateSixContainer"
				class="temp6-box"
				[ngClass]="{ templateTextWithId: templateTextWithId }"
			>
				<mva10-c-card id="templateComponentTemplateSixCard" [paddingTablet]="'xxl'" [paddingDesktop]="'xxl'">
					<div id="templateComponentTemplateSixCardContainer" class="temp-card6">
						<div
							id="{{ 'templateComponentTemplateSixCardItem' + i }}"
							*ngFor="let item of templateTextWithMedia; let i = index"
						>
							<div
								id="{{ 'templateComponentTemplateSixCardImgBox' + i }}"
								*ngIf="item.reference"
								class="temp-card6__icon"
							>
								<img id="{{ 'templateComponentTemplateSixCardImg' + i }}" [src]="item.reference" alt="topi-icon" />
							</div>
							<div id="{{ 'templateComponentTemplateSixCardContentBox' + i }}" *ngIf="item.value" class="iframe">
								<div
									id="{{ 'templateComponentTemplateSixCardContent' + i }}"
									class="temp-card6__text"
									[ngClass]="{
										'temp-card6__text-display-block': item?.id !== 'title',
										'temp-card6__text-title': item?.id === 'title',
										'temp-card6__text-subtitle': item?.id === 'subtitle'
									}"
									[innerHTML]="item.value | safe : 'html'"
								></div>
							</div>
						</div>
					</div>
				</mva10-c-card>
				<div id="templateComponentTemplateSixBtns" class="btns btns-six">
					<div id="templateComponentTemplateSixBtnsContainers" *ngFor="let btn of actions; let i = index">
						<button
							id="{{ 'templateComponentTemplateSixBtnsButton' + i }}"
							class="confirm button tempSixBtnRed"
							(click)="closeTemplateSixNavigate()"
						>
							{{ btn.label }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div id="templateComponentTemplateSevenTemplatesDiv" *ngIf="showTemplateSeven" class="temp7-container">
			<div
				id="templateComponentTemplateSevenBoxTemplatesDiv"
				class="temp7-box"
				[ngClass]="{ templateTextWithId: templateTextWithId }"
			>
				<mva10-c-card id="templateComponentCardTemplatesMva10card" [paddingTablet]="'xxl'" [paddingDesktop]="'xxl'">
					<div id="templateComponentCardTemplatesDiv" class="temp-card7">
						<div id="templateComponentCardMainTemplatesDiv" class="temp-card7__main">
							<img
								id="templateComponentMainIconTemplatesImage"
								class="temp-card7__main-icon"
								src="{{ appService.getImgFullPath('faultManagement.images.icono_engineer.url' | translate) }}"
								alt="topi-icon"
							/>
						</div>
						<div id="templateComponentFrameTemplatesDiv" class="iframe">
							<div id="templateComponentCardTitleTemplatesDiv" class="temp-card7__title">
								<p
									id="templateComponentCardTitleTextTemplatesParagraph"
									class="tempSeven__text-title"
									[innerHTML]="
										('faultManagement.itemsList.sevenTitle.body' | translate).replace('{0}', template.ticketId)
											| safe : 'html'
									"
								></p>
							</div>
							<div id="templateComponentCardSubitleTemplatesDiv" class="temp-card7__subtitle">
								<p
									id="templateComponentCardSubtitleTextTemplatesParagraph"
									class="tempSeven__text-subtitle"
									[innerHTML]="'faultManagement.itemsList.sevenSubtitle.body' | translate | safe : 'html'"
								></p>
							</div>
							<div id="templateComponentCardTipsTemplatesDiv" class="temp-card7__tips">
								<div id="templateComponentCardTipsIconOneTemplatesDiv" class="temp-card7__icon">
									<img
										id="templateComponentCardTipsIconOneTemplatesImage"
										class="temp-card7__icon-image"
										src="{{ appService.getImgFullPath('faultManagement.images.icono_call.url' | translate) }}"
										alt="call-icon"
									/>
								</div>
								<p
									id="templateComponentCardTipsTextOneTemplatesParagraph"
									class="tempSeven__tips-text"
									[innerHTML]="'faultManagement.itemsList.sevenTextImg1.body' | translate | safe : 'html'"
								></p>
								<div id="templateComponentCardTipsIconTwoTemplatesDiv" class="temp-card7__icon">
									<img
										id="templateComponentCardTipsIconTwoTemplatesImage"
										class="temp-card7__icon-image"
										src="{{ appService.getImgFullPath('faultManagement.images.icono_circle.url' | translate) }}"
										alt="circle-icon"
									/>
								</div>
								<p id="templateComponentCardTipsTextTwoTemplatesParagraph" class="tempSeven__tips-text">
									{{ 'faultManagement.itemsList.sevenTextImg2.body' | translate }}
								</p>
								<div id="templateComponentCardTipsIconThreeTemplatesDiv" class="temp-card7__icon">
									<img
										id="templateComponentCardTipsIconThreeTemplatesImage"
										class="temp-card7__icon-image"
										src="{{ appService.getImgFullPath('faultManagement.images.icono_price-promise.url' | translate) }}"
										alt="price_promise-icon"
									/>
								</div>
								<p id="templateComponentCardTipsTextThreeTemplatesParagraph" class="tempSeven__tips-text">
									{{ 'faultManagement.itemsList.sevenTextImg3.body' | translate }}
								</p>
							</div>
						</div>
					</div>
				</mva10-c-card>
				<div id="templateComponentButtonsTemplateSevenTemplatesDiv" class="btns-seven">
					<div>
						<button
							id="templateComponentConfirmButtonTemplatesButton"
							class="confirm button tempSevenBtn"
							(click)="closeTemplateSixNavigate()"
						>
							{{ 'faultManagement.buttonList.sevenButton.text' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div id="templateComponentTutorialTemplate8" class="temp8-container" *ngIf="showTemplateEight || showTemplateNine">
			<mva10-c-carousel
				[config]="carouselConfig"
				class="temp8-carousel"
				[secondary]="true"
				[positionIndex]="positionIndex"
			>
				<mva10-c-carousel-item *ngFor="let slide of slides; let i = index">
					<div class="text-image-container" id="textImageCarouselContainer">
						<div *ngIf="slide.valuesText1" class="temp8-text" [innerHTML]="slide.valuesText1 | safe : 'html'"></div>
						<div *ngIf="slide.valuesText2" class="temp8-text" [innerHTML]="slide.valuesText2 | safe : 'html'"></div>
						<div
							*ngIf="slide.valueImage1"
							[ngClass]="{ 'temp8-image1': showTemplateEight }"
							[ngClass]="{ 'temp9-image1': showTemplateNine }"
						>
							<img
								id="temp8CarouselImage"
								[src]="'faultManagement.images.' + slide.valueImage1 + '.url' | imgFullPath"
								alt=""
							/>
						</div>
					</div>
					<div *ngIf="slide.valuesText3" class="temp8-text" [innerHTML]="slide.valuesText3 | safe : 'html'"></div>
					<div *ngIf="slide.valueImage2" class="temp8-image2">
						<img [src]="'faultManagement.images.' + slide.valueImage2 + '.url' | imgFullPath" alt="" />
					</div>
					<div *ngIf="slide.valuesText4" class="temp8-text" [innerHTML]="slide.valuesText4 | safe : 'html'"></div>
					<div *ngIf="i === slides.length - 1" class="temp8-btn-container">
						<div *ngFor="let btn of actions; let i = index">
							<mva10-c-button
								[styleClass]="
									btn.color === template9BtnColors.red
										? faultManagementTemplateButtonClasses.primary
										: faultManagementTemplateButtonClasses.tertiary
								"
								[disabled]="positionIndex === 1"
								(click)="confirmBtn(btn.label, btn.value)"
							>
								{{ btn.label }}
							</mva10-c-button>
						</div>
					</div>
				</mva10-c-carousel-item>
			</mva10-c-carousel>
		</div>
		<div id="templateComponentTemplateTen" *ngIf="showTemplateTen" class="template10">
			<div
				id="templateComponentTemplateTenInfo"
				class="template10_infoBox"
				*ngFor="let item of templateTextWithMedia; let i = index"
			>
				<div
					id="templateComponentTemplateTenInfoImage"
					*ngIf="item.reference"
					class="{{ 'template10_infoBox_image' + i }}"
				>
					<img [src]="item.reference" />
				</div>
				<div
					id="templateComponentTemplateTenInfoText"
					*ngIf="item.value"
					class="template10_infoBox_text"
					[attr.style]="'font-size:' + item.size + 'px;'"
					[innerHTML]="item.value | safe : 'html'"
				></div>
			</div>
			<div id="templateComponentTemplateTenInputs" class="template10_inputBox form-input">
				<ng-container *ngIf="formInputs">
					<div
						id="templateComponentTemplateTenInputsInput"
						class="template10_inputBox_input"
						*ngFor="let input of formInputs; let i = index"
					>
						<ng-template [ngIf]="input?.type == 'textarea'" [ngIfElse]="otherInputTypes">
							<sp-text-area
								ngDefaultControl
								[id]="input?.id"
								[max]="input?.maxsize"
								[formControlName]="input.name"
								[patternErrorMessage]="input.errorMessage"
								class="comment"
								(focusFnEvent)="input.showErrorMsg = false"
								(blurFnEvent)="input.showErrorMsg = true"
								[enforceValidation]="input.showErrorMsg"
								[isRequired]="input?.required"
								[dirtyValidationCheck]="true"
								[pattern]="templatesService.checkValidation(input)"
								[mva10Textbox]="true"
								[placeholder]="input.label"
								[areaRowsNo]="5"
								[textType]="input.type"
								[showError]="
									templateForm.controls[input?.name]?.value?.length < input.minsize &&
									(templateForm.controls[input?.name]?.value?.length >= 1 ||
										templateForm.controls[input?.name]?.dirty) &&
									input.showErrorMsg
								"
							>
							</sp-text-area>
						</ng-template>
						<ng-template #otherInputTypes>
							<sp-text
								ngDefaultControl
								(focusFnEvent)="input.showErrorMsg = false"
								(blurFnEvent)="input.showErrorMsg = true"
								(contentChange)="dateValidation(input)"
								id="templateComponentInput?.id"
								[max]="input?.maxsize"
								formControlName="{{ input.name }}"
								[patternErrorMessage]="input.errorMessage"
								[placeholder]="input.label"
								[pattern]="templatesService?.checkValidation(input)"
								[enforceValidation]="input.showErrorMsg"
								[dirtyValidationCheck]="true"
								[isRequired]="input?.required"
								[mva10Textbox]="true"
								[redHelperText]="true"
								[showRightIconSuccess]="false"
							>
							</sp-text>
						</ng-template>
					</div>
				</ng-container>
				<ng-container *ngIf="formItems">
					<div
						id="templateComponentTemplateTenFormItems"
						class="template10_formitem"
						*ngFor="let formItem of formItems; let i = index"
					>
						<ng-container [ngSwitch]="formItem.name">
							<div
								id="templateComponentTemplateTenFormItemTitle"
								class="TemplateTenFormItemTitle"
								*ngSwitchCase="formItemsNames.Title"
							>
								<p
									id="formitemTitle"
									[style.text-align]="formItem.component.text_aligned || 'left'"
									[innerHTML]="formItem.component.text_label"
								></p>
							</div>
							<div
								id="templateComponentTemplateTenFormItemSubtitle"
								class="TemplateTenFormItemSubtitle"
								*ngSwitchCase="formItemsNames.Subtitle"
							>
								<p
									id="formitemSubTitle"
									[style.text-align]="formItem.component.text_aligned || 'left'"
									[innerHTML]="formItem.component.text_label"
								></p>
							</div>
							<div
								id="templateComponentTemplateTenFormItemText"
								class="TemplateTenFormItemText"
								*ngSwitchCase="formItemsNames.Text"
							>
								<p
									id="formitemText"
									[style.text-align]="formItem.component.text_aligned || 'left'"
									[innerHTML]="formItem.component.text_label"
								></p>
							</div>
							<div
								id="templateComponentTemplateTenFormItemImage"
								class="TemplateTenFormItemImage"
								*ngSwitchCase="formItemsNames.Image"
							>
								<img
									id="templateTenImage"
									[src]="
										'faultManagement.images.' + formItem.component.image_reference.split('.')[0] + '.url'
											| translate
											| imgFullPath
									"
									alt="template10icon"
									[height]="formItem.component.image_height"
								/>
							</div>
							<div
								id="templateComponentTemplateTenFormItemParagraph"
								class="TemplateTenFormItemParagraph"
								*ngSwitchCase="formItemsNames.Paragraph"
							>
								<p
									id="formitemparagraph"
									[style.text-align]="formItem.component.text_aligned || 'left'"
									[innerHTML]="formItem.component.text_label"
									[ngClass]="{ 'super-wifi-solution': template.taskId === 'SuperWifiSoluciones2' }"
								></p>
							</div>
							<div
								id="templateComponentTemplateTenFormItemIconTextRow"
								class="TemplateTenFormItemIconTextRow"
								*ngSwitchCase="formItemsNames.IconTextRow"
								[ngClass]="{ 'border-bottom': i !== formItems.length - 1 }"
							>
								<img
									id="templateTenImage"
									[src]="
										'faultManagement.images.' + formItem.component.image_reference + '.url' | translate | imgFullPath
									"
									alt="template10icon"
									[height]="formItem.component.image_height"
								/>
								<p
									id="formitemparagraph"
									[style.text-align]="formItem.component.text_aligned || 'left'"
									[innerHTML]="formItem.component.text_label"
								></p>
							</div>
							<form
								id="templateComponentTemplateTenFormItemCheckButtons"
								[formGroup]="templateForm"
								class="TemplateTenFormItemCheckButtons"
								*ngSwitchCase="formItemsNames.CheckButton"
							>
								<div
									[id]="'TemplateTenFormItemCheckButton' + ind"
									class="Template10FormItemCheckButton"
									*ngFor="let checkboxItem of formItem.component.list; let ind = index"
								>
									<span [id]="'checkboxlabel' + ind">{{ checkboxItem.label }}</span>
									<mva10-c-inputs
										[type]="1"
										[value]="checkboxItem.value"
										(mva10Change)="checkboxTemplate10Change(formItem.id, checkboxItem.value, $event)"
										[inputId]="formItem.id"
									>
									</mva10-c-inputs>
								</div>
							</form>
							<form
								id="templateComponentTemplateTenFormItemRadioButtons"
								[formGroup]="templateForm"
								class="TemplateTenFormItemRadioButtons"
								*ngSwitchCase="formItemsNames.RadioButton"
							>
								<div
									[id]="'TemplateTenFormItemRadioButton' + ind"
									class="Template10FormItemRadioButton"
									*ngFor="let radioItem of formItem.component.list; let ind = index"
								>
									<span [id]="'radiolabel' + ind">{{ radioItem.label }}</span>
									<mva10-c-inputs
										[type]="0"
										[name]="formItem.id"
										[formControlName]="formItem.id"
										[checked]="templateForm.get(formItem.id).value === radioItem.value"
										[value]="radioItem.value"
										[inputId]="formItem.id"
									>
									</mva10-c-inputs>
								</div>
							</form>
							<form
								id="templateComponentTemplateTenFormItemToggleButtons"
								[formGroup]="templateForm"
								class="TemplateTenFormItemToggleButtons"
								*ngSwitchCase="formItemsNames.ToggleButton"
							>
								<div
									[id]="'TemplateTenFormItemToggleButton' + ind"
									class="Template10FormItemToggleButton"
									*ngFor="let toggleItem of formItem.component.list; let ind = index"
								>
									<span [id]="'togglelabel' + ind">{{ toggleItem.label }}</span>
									<mva10-c-inputs
										[type]="2"
										[value]="toggleItem.value"
										[inputId]="formItem.id"
										(mva10Change)="checkboxTemplate10Change(formItem.id, toggleItem.value, $event)"
									>
									</mva10-c-inputs>
								</div>
							</form>
							<form
								id="templateComponentTemplateTenFormItemForm"
								[formGroup]="templateForm"
								class="TemplateTenFormItemForm"
								*ngSwitchCase="formItemsNames.Form"
							>
								<ng-template
									id="{{ 'templateComponentTemplateTenFormInputsBox' + i }}"
									[ngIf]="formItem.component.type == templateTenTextarea"
								>
									<sp-text-area
										id="{{ 'templateComponentTemplateTenTextArea' + i }}"
										ngDefaultControl
										[id]="formItem.component?.id"
										[max]="formItem.component?.maxsize"
										[formControlName]="formItem.component.name"
										[patternErrorMessage]="formItem.component.errorMessage"
										[ngClass]="{ comment: formItem.component?.type == templateTenTextarea }"
										(focusFnEvent)="formItem.component.showErrorMsg = false"
										(blurFnEvent)="formItem.component.showErrorMsg = true"
										[enforceValidation]="formItem.component.showErrorMsg"
										[isRequired]="formItem.component?.required"
										[dirtyValidationCheck]="true"
										[pattern]="templatesService.checkValidation(formItem.component)"
										[mva10Textbox]="true"
										[placeholder]="formItem.label || formItem.component?.label"
										[areaRowsNo]="5"
										[showError]="
											templateForm.controls[formItem.component?.name]?.value?.length < formItem.component.minsize &&
											(templateForm.controls[formItem.component?.name]?.value?.length >= 1 ||
												templateForm.controls[formItem.component?.name]?.dirty) &&
											formItem.component.showErrorMsg
										"
									>
									</sp-text-area>
								</ng-template>
								<ng-template
									id="{{ 'templateComponentTemplateTenFormInputsBox' + i }}"
									[ngIf]="
										formItem.component?.type == templateTenText && formItem.component.validator === cellDateValidator
									"
								>
									<mva10-c-date-field
										[minDate]="minDate"
										[maxDate]="maxDate"
										[label]="formItem.label || formItem.component?.label"
										(dateChange)="dateChange(formItem.component, $event)"
									>
									</mva10-c-date-field>
								</ng-template>
								<ng-template
									id="{{ 'templateComponentTemplateTenFormInputsBox' + i }}"
									[ngIf]="
										(formItem.component?.type != templateTenText ||
											(formItem.component?.type == templateTenText &&
												formItem.component.validator !== cellDateValidator)) &&
										formItem.component?.type !== templateTenTextarea
									"
								>
									<sp-text
										id="{{ 'templateComponentTemplateTenText' + i }}"
										ngDefaultControl
										(focusFnEvent)="formItem.component.showErrorMsg = false"
										(blurFnEvent)="formItem.component.showErrorMsg = true"
										(contentChange)="dateValidation(formItem.component)"
										[ngClass]="{ comment: formItem.component?.type == templateTenTextarea }"
										[max]="formItem.component?.maxsize"
										formControlName="{{ formItem.component.name }}"
										[patternErrorMessage]="formItem.component.errorMessage"
										[placeholder]="formItem.label || formItem.component?.label"
										[pattern]="templatesService.checkValidation(formItem.component)"
										[enforceValidation]="formItem.component.showErrorMsg"
										[dirtyValidationCheck]="true"
										[isRequired]="formItem.component?.required"
										[mva10Textbox]="true"
										[redHelperText]="true"
										[showRightIconSuccess]="false"
									>
									</sp-text>
								</ng-template>
							</form>
							<div
								id="templateComponentTemplateTenFormItemNavButtons"
								class="TemplateTenFormItemNavButtons"
								[formGroup]="templateForm"
								*ngSwitchCase="formItemsNames.NavButton"
							>
								<div
									[id]="'TemplateTenFormItemNavButton' + ind"
									class="Template10FormItemNavButton"
									*ngFor="let navItem of formItem.component.list; let ind = index"
								>
									<div
										id="{{ 'templateComponentTemplateTenAction' + i + 'Box' }}"
										class="jumptron-cell"
										[formControlName]="formItem.id"
										(click)="getNavBtnTemplate10NextTemplate(formItem.id, navItem.label, navItem.value)"
									>
										<p
											id="{{ 'templateComponentTemplateTenAction' + i + 'Paragraph' }}"
											[innerHTML]="navItem.label"
										></p>
										<span id="{{ 'templateComponentTemplateTenAction' + i + 'Icon' }}" class="icon icon-chevron-right">
										</span>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
			<div id="templateComponentTemplateTenFooter" class="template10_footerBox">
				<div id="templateComponentTemplateTenCheckbox" *ngIf="template.form.checkbox" class="template10-checkbox">
					<ng-container *ngFor="let checkItem of template.form.checkbox">
						<div class="checkbox-container">
							<mva10-c-inputs
								[type]="1"
								[value]="checkItem.checkboxValue"
								(mva10Change)="checkItem.checkboxValue = $event"
							>
							</mva10-c-inputs>
							<p id="checkboxlabel" [innerHTML]="checkItem.label"></p>
						</div>
					</ng-container>
				</div>
				<div
					id="templateComponentTemplateTenFooterLastText"
					*ngIf="lastText?.value"
					class="template10_footerBox_lastText"
					[innerHTML]="lastText?.value | safe : 'html'"
					[attr.style]="'font-size:' + lastText?.size + 'px;'"
				></div>
				<div id="templateComponentTemplateTenFooterButtons" class="template10_footerBox_buttons">
					<div
						id="templateComponentTemplateTenFooterButtonsButton"
						class="template10_footerBox_buttons_button"
						*ngFor="let btn of actions; let i = index"
					>
						<div></div>
						<button
							[ngClass]="{
								template10_footerBox_buttons_button_red: btn.color === 'red',
								template10_footerBox_buttons_button_white: btn.color === '' || 'white'
							}"
							type="submit"
							[disabled]="!templateForm.valid || (btn.affectedByCheckbox && !template10ActionBtnEnabled)"
							class="confirm button mva10Btn"
							(click)="confirmBtn(btn.label, btn.value)"
							[innerHTML]="btn.label"
						></button>
						<div></div>
					</div>
				</div>
			</div>
		</div>
		<!--template 11-->
		<div id="templateComponentTemplateEleven" *ngIf="showTemplateEleven" class="template11">
			<div class="chat-parent">
				<div
					class="chat-recorded"
					*ngFor="let chatMessage of chatMessages; let i = index"
					[ngClass]="{ isOwn: !chatMessage.isOwn }"
					[ngStyle]="{
						'margin-left': !chatMessage.isOwn ? 'auto' : 0
					}"
				>
					<div class="chat-message">
						<img
							*ngIf="chatMessage.isOwn"
							[id]="'vodafoneIcon' + i"
							[src]="'v10.faultManagement.messagesList.templates.t11chatRecorded.agentIcon' | translate | imgFullPath"
							alt="icon"
						/>
						<div class="msg-bubble">
							<p [id]="'messageContent' + i">{{ chatMessage.messageContent }}</p>
						</div>
					</div>
					<span class="date" [id]="'receivedAt' + i">{{ chatMessage.receivedAt | date : 'hh:mm - dd/MM/yyyy' }}</span>
				</div>
			</div>
			<mva10-c-bottom-tray-footer
				[appearance]="
					t11ChatRecordedWCS?.redButton?.visibility === t11BtnHide ||
					t11ChatRecordedWCS?.whiteButton?.visibility === t11BtnHide
						? 'text-button-link'
						: 'text-buttons'
				"
				(mainButtonClick)="template11RedBtnClickHandler()"
				(secondaryButtonClick)="template11WhiteBtnClickHandler()"
			>
				<span
					btf-text-title
					id="template11Title"
					[innerHTML]="
						t11ChatRecordedWCS?.footerText ||
						('v10.faultManagement.messagesList.templates.t11chatRecorded.footerText' | translate)
					"
				></span>
				<span
					*ngIf="t11ChatRecordedWCS?.redButton && t11ChatRecordedWCS?.redButton.visibility === t11BtnVisible"
					btf-main-button
					id="template11MainButton"
				>
					{{
						t11ChatRecordedWCS?.redButton.text ||
							'v10.faultManagement.messagesList.templates.t11chatRecorded.redButton.text' | translate
					}}
				</span>
				<span
					*ngIf="t11ChatRecordedWCS?.whiteButton && t11ChatRecordedWCS?.whiteButton.visibility === t11BtnVisible"
					btf-secondary-button
					id="template11SecondaryButton"
				>
					{{
						t11ChatRecordedWCS?.whiteButton.text ||
							'v10.faultManagement.messagesList.templates.t11chatRecorded.reabrir_averia_chatrecorded.whiteButton.text'
							| translate
					}}
				</span>
			</mva10-c-bottom-tray-footer>
		</div>
	</div>
	<mva10-c-modal
		id="templateComponentErrorModal"
		*ngIf="error"
		[styleClass]="''"
		[show]="error"
		(closeBtnClicked)="failurConfirmBtn()"
	>
		<div id="templateComponentErrorModalContainer" class="confirm-error">
			<div id="templateComponentErrorModalImgBox" class="modal-icon">
				<img id="templateComponentErrorModalImg" [src]="modalIcon" />
			</div>
			<div id="templateComponentErrorModalTittleBox" class="modal-title">
				<p id="templateComponentErrorModalTittleBoxParagraph">{{ modalTitle }}</p>
			</div>
			<div id="templateComponentErrorModalSecTittleBox" class="modal-sec_title">
				<p
					id="templateComponentErrorModalSecTittleBoxParagraph"
					[innerHtml]="
						'faultManagement.messagesList.newFaultGeneralErrorMsg.newFaultGeneralErrorMsg_description' | translate
					"
				></p>
			</div>
			<div id="templateComponentErrorModalButtonBox" class="confirm">
				<mva10-c-button
					id="templateComponentErrorModalButton"
					[body]="modalBtn1"
					[styleClass]="'mva10-c-button--primary'"
					[name]="'failure-confirm'"
					(click)="failureConfirmClose()"
				>
				</mva10-c-button>
			</div>
		</div>
	</mva10-c-modal>
	<div
		id="templateComponentDataTextTracker"
		class="data-text-tracker"
		*ngIf="spinnerWithSteps && stepNumber !== 0 && !isTipsForDeactivateCallsRestrictionsActive"
	>
		<div id="templateComponentDataTextTrackerProgressBar" class="data-text-tracker_progress-bar">
			<div
				*ngIf="isKeepWaitingHFC"
				id="templateComponentDataTextTrackerProgressBarStatusHFC"
				class="data-text-tracker_progress-bar-status-{{ stepNumber }}"
			></div>
			<div
				*ngIf="isKeepWaitingReboot"
				id="templateComponentDataTextTrackerProgressBarStatusReboot"
				class="data-text-tracker_progress-bar-status-{{ stepNumber + 4 }}"
			></div>
		</div>
	</div>
	<div
		id="templateComponentDataTextTracker"
		class="data-text-tracker"
		*ngIf="isTipsForDeactivateCallsRestrictionsActive"
	>
		<div id="templateComponentDataTextTrackerText" class="data-text-tracker_text data-text-tracker_text--content-right">
			<div
				id="templateComponentDataTextTrackerTextTwo"
				class="data-text-tracker_text_step-number data-text-tracker_text_step-number"
			>
				<p id="templateComponentDataTextTrackerTextTwoParagraph" class="data-text-tracker_text_title">
					{{ percentageTipsForDeactivateCallsRestrictions }}
				</p>
			</div>
		</div>
		<div id="templateComponentDataTextTrackerProgressBar" class="data-text-tracker_progress-bar">
			<div
				id="templateComponentDataTextTrackerProgressBarStatusHFC"
				class="data-text-tracker_progress-bar-status-{{ stepNumber }}"
			></div>
		</div>
	</div>
	<div
		id="templateComponentBonitaLottieAnimation"
		class="bonita-lottie-animation"
		*ngIf="loading || firstLoading || spinnerWithSteps"
	>
		<ng-lottie [options]="lottieConfig" (animationCreated)="handleAnimation($event)"> </ng-lottie>
	</div>
	<div
		id="templateComponentSpinnerWithTips"
		class="spinnerSiteText"
		*ngIf="loading && spinnerWithText && spinnerWithTips && !showTemplateSeven"
	>
		<p id="templateComponentSpinnerWithTipsParagraphOne" [innerHtml]="innerTextQuestion"></p>
		<p
			id="templateComponentSpinnerWithTipsParagraphTwo"
			[innerHtml]="innerText || 'faultManagement.itemsList.templateFourLoading.body' | translate"
		></p>
	</div>
	<div
		id="templateComponentSpinnerWithoutTips"
		class="spinnerSiteText"
		*ngIf="loading && spinnerWithText && !spinnerWithTips && !showTemplateSeven"
	>
		<p id="templateComponentSpinnerWithoutTipsParagraphOne" [innerHtml]="innerTextQuestion"></p>
		<p
			id="templateComponentSpinnerWithoutTipsParagraphTwo"
			[innerHtml]="innerText || 'faultManagement.itemsList.chatLoading.body' | translate"
		></p>
	</div>
	<div id="templateComponentSpinnerWithSteps" class="spinnerSteps" *ngIf="spinnerWithSteps || isWaitingSuperWifiCheck">
		<div id="spinnerStepsProgress" class="spinnerSteps_progress">
			<p
				id="templateComponentSpinnerWithStepsParagraphOne"
				class="spinnerSteps_text"
				[innerHtml]="'faultManagement.itemsList.loadingScreen.contentList.label.body' | translate"
			></p>
			<p
				class="spinnerSteps_subtitle"
				[innerHtml]="'faultManagement.itemsList.loadingScreen.contentList.subtitle.body' | translate"
			></p>
			<ul class="spinnerSteps_list">
				<li [class.active]="stepNumber >= 1" [class.completed]="stepNumber >= 1">
					<mva10-c-icon
						[size]="2"
						[iconId]="'icon-tick-circle'"
						title="step1"
						[class.success-icon]="stepNumber >= 1"
						[class.primary-icon]="stepNumber < 1"
					>
					</mva10-c-icon>
					<span
						class="step-text"
						[class.bold]="stepNumber >= 1"
						[innerHtml]="'faultManagement.itemsList.loadingScreen.contentList.step_text_1.body' | translate"
					></span>
				</li>
				<li [class.active]="stepNumber >= 2" [class.completed]="stepNumber >= 2">
					<mva10-c-icon
						[size]="2"
						[iconId]="'icon-tick-circle'"
						title="step2"
						[class.success-icon]="stepNumber >= 2"
						[class.primary-icon]="stepNumber < 2"
					>
					</mva10-c-icon>
					<span
						class="step-text"
						[class.bold]="stepNumber >= 2"
						[innerHtml]="
							(isMVL
								? 'faultManagement.itemsList.loadingScreen.contentList.mvl_step_text_2.body'
								: 'faultManagement.itemsList.loadingScreen.contentList.step_text_2.body'
							) | translate
						"
					></span>
				</li>
				<li [class.active]="stepNumber >= 3" [class.completed]="stepNumber >= 3">
					<mva10-c-icon
						[size]="2"
						[iconId]="'icon-tick-circle'"
						title="step3"
						[class.success-icon]="stepNumber >= 3"
						[class.primary-icon]="stepNumber < 3"
					>
					</mva10-c-icon>
					<span
						class="step-text"
						[class.bold]="stepNumber >= 3"
						[innerHtml]="'faultManagement.itemsList.loadingScreen.contentList.step_text_3.body' | translate"
					></span>
				</li>
				<li *nfIf="isMVL" [class.active]="stepNumber >= 4" [class.completed]="stepNumber >= 4">
					<mva10-c-icon
						[size]="2"
						[iconId]="'icon-tick-circle'"
						title="step4"
						[class.success-icon]="stepNumber >= 4"
						[class.primary-icon]="stepNumber < 4"
					>
					</mva10-c-icon>
					<span
						class="step-text"
						[class.bold]="stepNumber >= 4"
						[innerHtml]="'faultManagement.itemsList.loadingScreen.contentList.step_text_4.body' | translate"
					></span>
				</li>
			</ul>
		</div>
	</div>

	<vfac-overlay
		id="templateComponentOverlayOne"
		[overlayData]="headerExitBonitaData"
		[show]="faultManagementService.showOverlay"
		[loaded]="true"
		(closed)="templatesService.closeOverlay()"
		[page]="2"
	>
		<div id="templateComponentOverlayOneContent" body class="ovrlay-modal-body">
			<img
				id="templateComponentOverlayOneContentImg"
				class="overlay-icon"
				[src]="exitBonitaOverlayUiData.icon"
				alt=""
			/>
			<div id="templateComponentOverlayOneContentBody" class="text-content">
				<div id="templateComponentOverlayOneContentBodyText" class="description">
					<p
						id="templateComponentOverlayOneContentBodyTextParagraph"
						[innerHtml]="exitBonitaOverlayUiData.description | safe : 'html'"
					></p>
				</div>
			</div>
			<div id="templateComponentOverlayOneContentFooter" class="btn-container">
				<div id="templateComponentOverlayOneContentFooterContainerOne" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayOneContentFooterContainerOneButton"
						[body]="exitBonitaOverlayUiData.buttonText"
						[styleClass]="'mva10-c-button--primary'"
						[name]="'overlayData.primaryButton'"
						(click)="exitBonitaFlowWithProblemSolved()"
					>
					</mva10-c-button>
				</div>
				<div id="templateComponentOverlayOneContentFooterContainerTwo" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayOneContentFooterContainerTwoButton"
						[body]="exitBonitaOverlayUiData.button2Text"
						[styleClass]="'mva10-c-button--tertiary'"
						[name]="'overlayData.secondaryButton'"
						(click)="checkTransformersOK()"
					>
					</mva10-c-button>
				</div>
			</div>
		</div>
	</vfac-overlay>
	<vfac-overlay
		id="templateComponentOverlayTwo"
		[overlayData]="headerExitBonitaData"
		[show]="faultManagementService.getShowSecondOverlay()"
		[loaded]="true"
		(closed)="templatesService.closeSecondOverlay()"
		[page]="2"
	>
		<div id="templateComponentOverlayTwoContent" body class="ovrlay-modal-body">
			<img
				id="templateComponentOverlayTwoContentImg"
				class="overlay-icon"
				[src]="exitBonitaOverlayUiData.icon"
				alt=""
			/>
			<div id="templateComponentOverlayTwoContentBody" class="text-content">
				<div id="templateComponentOverlayTwoContentBodyText" class="description">
					<p
						id="templateComponentOverlayTwoContentBodyTextParagraph"
						[innerHtml]="'faultManagement.newTickets.messagesList.transformerOkOverlay.title' | translate"
					></p>
				</div>
			</div>
			<div id="templateComponentOverlayTwoContentFooter" class="btn-container">
				<div id="templateComponentOverlayTwoContentFooterContainerOne" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayTwoContentFooterContainerOneButton"
						[body]="'faultManagement.newTickets.messagesList.transformerOkOverlay.button1.text' | translate"
						[styleClass]="'mva10-c-button--primary'"
						[name]="'overlayData.primaryButton'"
						(click)="templatesService.closeSecondOverlay()"
					>
					</mva10-c-button>
				</div>
				<div id="templateComponentOverlayTwoContentFooterContainerTwo" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayTwoContentFooterContainerTwoButton"
						[body]="'faultManagement.newTickets.messagesList.transformerOkOverlay.button2.text' | translate"
						[styleClass]="'mva10-c-button--tertiary'"
						[name]="'overlayData.secondaryButton'"
						(click)="exitBonitaFlowWithProblemNotSolved(InteractionId.notSolved)"
					>
					</mva10-c-button>
				</div>
			</div>
		</div>
	</vfac-overlay>
	<vfac-overlay
		id="templateComponentOverlayThree"
		[overlayData]="headerExitBonitaData"
		[show]="faultManagementService.getShowThirdOverlay()"
		[loaded]="true"
		(closed)="templatesService.closeThirdOverlay()"
		[page]="2"
	>
		<div id="templateComponentOverlayThreeContent" body class="ovrlay-modal-body">
			<img
				id="templateComponentOverlayThreeContentImg"
				class="overlay-icon"
				[src]="'faultManagement.images.icono_warning.url' | imgFullPath"
				alt=""
			/>
			<div id="templateComponentOverlayThreeContentBody" class="text-content">
				<div id="templateComponentOverlayThreeContentBodyText" class="description">
					<p
						id="templateComponentOverlayThreeContentBodyTextParagraph"
						[innerHtml]="'faultManagement.newTickets.messagesList.rebootSolvedOverlay.title' | translate"
					></p>
				</div>
			</div>
			<div id="templateComponentOverlayThreeContentFooter" class="btn-container">
				<div id="templateComponentOverlayThreeContentFooterContainerOne" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayThreeContentFooterContainerOneButton"
						[body]="'faultManagement.newTickets.messagesList.rebootSolvedOverlay.button1.text' | translate"
						[styleClass]="'mva10-c-button--primary'"
						[name]="'overlayData.primaryButton'"
						(click)="templatesService.closeThirdOverlay()"
					>
					</mva10-c-button>
				</div>
				<div id="templateComponentOverlayThreeContentFooterContainerThree" class="overlay-btn">
					<mva10-c-button
						id="templateComponentOverlayThreeContentFooterContainerThreeButton"
						[body]="'faultManagement.newTickets.messagesList.rebootSolvedOverlay.button2.text' | translate"
						[styleClass]="'mva10-c-button--tertiary'"
						[name]="'overlayData.secondaryButton'"
						(click)="customBack()"
					>
					</mva10-c-button>
				</div>
			</div>
		</div>
	</vfac-overlay>
	<mva10-c-modal
		*ngIf="showTemplatePopup"
		class="template-popup popupGradient"
		[show]="showTemplatePopup"
		(closeBtnClicked)="confirmBtn(actions[1]?.label, actions[1]?.value)"
	>
		<div *ngFor="let item of templateTextWithMedia; let i = index" class="template-popup-content">
			<div class="iframe" *ngIf="item.value">
				<p [ngClass]="{ firstText: i === 0 }" [innerHTML]="item.value | safe : 'html'"></p>
			</div>
			<div *ngIf="item.reference">
				<img [src]="item.reference" class="img-bonita" />
			</div>
		</div>
		<div class="btns-popup">
			<div *ngFor="let btn of actions; let i = index">
				<mva10-c-button
					[body]="btn.label"
					[styleClass]="i === 0 ? 'mva10-c-button--primary' : 'mva10-c-button--tertiary'"
					(click)="confirmBtn(btn.label, btn.value)"
				>
				</mva10-c-button>
			</div>
		</div>
	</mva10-c-modal>
</div>
