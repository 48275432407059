<div
	class="vfac-side-menu mva10-u--1/1"
	[ngClass]="{ 'mva10-o-layout__item': expanded || breakpoint == BREAKPOINTS.DESKTOP }"
>
	<div class="mva10-u--pb5x" [ngClass]="expanded ? 'text-align-left' : 'text-align-center'">
		<div
			*ngIf="iconError"
			id="sharedLeftMenuErrorIcon"
			class="vfac-side-menu__logo cursor-pointer"
			(keyUp)="logoClick()"
			[ngClass]="{ 'vfac-side-menu__logo--desktop': bigSize }"
			id="sharedLeftMenuLogoClick"
			(click)="logoClick()"
		>
			<svg [ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg'">
				<title>Logo_Vodafone</title>
				<use xlink:href="#icon-vodafone-esp" />
			</svg>
		</div>

		<div *ngIf="!iconError">
			<div
				*ngIf="!isYuBit"
				id="sharedLeftMenuDynamicIcon"
				class="vfac-side-menu__logo cursor-pointer"
				[ngClass]="{ 'vfac-side-menu__logo--desktop': bigSize }"
				(click)="logoClick()"
				(keyUp)="logoClick()"
			>
				<img
					*ngIf="!vfFlagAnimation"
					[ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg'"
					src="{{ vfIcon }}"
					alt="sideMenu"
				/>
				<div
					*ngIf="vfFlagAnimation"
					[ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg, animationAjust'"
				>
					<div [ngClass]="expanded && !bigSize ? 'animationAjustExpanded' : 'dummy'">
						<sp-lottie-loader [lottieImageUrl]="vfIcon"> </sp-lottie-loader>
					</div>
				</div>
			</div>

			<div *ngIf="isYuBit" class="vfac-side-menu__logo">
				<img
					id="sharedLeftMenuImgYu"
					*ngIf="!yuBitFlagAnimation"
					alt="youBit"
					[ngClass]="bigSize ? 'vfac-side-menu__yuBitIcon--xl' : 'vfac-side-menu__yuBitIcon'"
					src="{{ yuBitIcon }}"
				/>
				<div
					*ngIf="yuBitFlagAnimation"
					[ngClass]="bigSize ? 'vfac-side-menu__yuBitIcon--xl' : 'vfac-side-menu__yuBitIcon, animationAjust'"
				>
					<div [ngClass]="expanded ? 'animationAjustExpanded' : 'dummy'">
						<sp-lottie-loader [lottieImageUrl]="yuBitIcon"> </sp-lottie-loader>
					</div>
				</div>
			</div>
		</div>
		<fieldset *ngIf="leftMenuService.leftMenuData">
			<div
				*ngFor="let item of leftMenuService.leftMenuData.items; let itemIdx = index"
				class="vfac-side-menu__item"
				[ngStyle]="{ 'justify-content': !expanded && !bigSize ? 'center' : 'left' }"
				[ngClass]="{
					'vfac-side-menu__item--selected': itemSelected === itemIdx,
					'vfac-side-menu__item--desktop': bigSize,
				}"
			>
				<mva10-c-menu-list
					*ngIf="item.type !== SideMenuItemType.PLACEHOLDER"
					[badgeNum]="item.badgeText"
					[badgePosition]="'left'"
					[body]="expanded || bigSize ? item.text : ''"
					[iconLeft]="item.iconClassName"
					[ngClass]="expanded || bigSize ? 'mva10-inverse' : 'mva10-inverse hideMenuSpan'"
					(clickEvent)="onMenuClick(item, itemIdx)"
				>
				</mva10-c-menu-list>
				<div
					class="sidemenu__button-skeleton__container"
					*ngIf="item.type === SideMenuItemType.PLACEHOLDER"
					id="leftMenuButtonSkeleton"
				>
					<div class="skeleton sidemenu__button-skeleton__icon"></div>
					<div class="skeleton sidemenu__button-skeleton__text"></div>
				</div>
			</div>
		</fieldset>
	</div>
	<ng-container *ngIf="bigSize">
		<div
			#adaraContainer
			*ngIf="!leftMenuService.errorCif && !telcoFilter"
			class="vfac-side-menu__products__container"
			[ngClass]="{
				'vfac-side-menu__products__container--gradientTop': viewMoreClick && showGradientTop,
				'vfac-side-menu__products__container--gradientBottom': viewMoreClick && showGradientBottom
			}"
		>
			<div
				#menuAdara
				*ngIf="leftMenuService.productSelectorLoad; else skeleton"
				(scroll)="scrollProducts()"
				class="vfac-side-menu__products scrollbar-hidden"
				[ngClass]="{
					'mva10-o-layout__item': !bigSize,
					'vfac-side-menu__products--clicked vfac-side-menu__products--scroll-open': viewMoreClick,
					'vfac-side-menu__products--unclicked vfac-side-menu__products--scroll-blocked': !viewMoreClick
				}"
			>
				<div *ngIf="customerAccountService.hasAdaraSite">
					<div
						*ngFor="let item of customerAccountService.adaraSelectorData?.cardImageSelectorList"
						[ngStyle]="{ 'justify-content': !expanded && !bigSize ? 'center' : 'left' }"
						id="sharedLeftMenuSelectedProduct"
						(click)="clickOnAdaraCTATags()"
						(keyUp)="clickOnAdaraCTATags()"
					>
						<vfac-card-product
							[svg]="'#icon-idea-or-innovation-mid'"
							[title]="item.title"
							[description]="item.description"
							[checked]="item.checked"
							[size]="'small'"
							[colorBg]="item.backgroundColor"
							[borderColor]="'white'"
							extraClass="mva10-u--mb2x"
						>
						</vfac-card-product>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<div
		[ngClass]="{
			'footer-side-menu--spaced':
				customerAccountService.selectorDataWithSubscriptions?.cardImageSelectorList?.length > 4
		}"
		class="footer-side-menu mva10-u--pb6x"
	></div>
</div>

<vfac-overlay
	[show]="showChangeCifOverlay"
	[loaded]="customerAccountService.selectorCIFData"
	[overlayData]="overlayData"
	[showButtons]="false"
	(closed)="showChangeCifOverlay = false"
>
	<div body class="mva10-u--mt12x">
		<mva10-c-tabs-simple
			*ngIf="customerAccountService.selectorCIFData && customerAccountService.selectorCIFData.length > 1"
			[dataSelectTab]="leftMenuService.cifTabIdx"
			[styleInline]="{ 'margin-bottom': '24px' }"
			(mva10OnClick)="this.leftMenuService.setCifSelectorClicked($event)"
		>
			<mva10-c-tab *ngFor="let tab of customerAccountService.selectorCIFData; let i = index" [title]="tab">
				<ng-container *ngIf="leftMenuService.addressSelectorLoaded; then overlayContent; else skeleton2">
				</ng-container>
			</mva10-c-tab>
		</mva10-c-tabs-simple>

		<ng-container
			*ngIf="!customerAccountService.selectorCIFData || customerAccountService.selectorCIFData.length <= 1"
			[ngTemplateOutlet]="overlayContent"
		>
		</ng-container>

		<vfac-selector
			*ngIf="!leftMenuService.isSitePendingInstallation"
			[yuAppearance]="this.appService.checkYuUser()"
			[selectorData]="customerAccountService.selectorData"
			[startAtIndex]="leftMenuService.startIndexSelectorCard"
			(cardClickEvent)="selectedProductModal($event)"
			[listLoaded]="leftMenuService.productSelectorLoad"
		>
		</vfac-selector>

		<sp-pending-install-warning
			id="sharedLeftMenuClickMyOrders"
			*ngIf="leftMenuService.isSitePendingInstallation"
			(linkClick)="goToMyOrders()"
		>
		</sp-pending-install-warning>
	</div>
</vfac-overlay>

<ng-template #overlayContent>
	<div class="overlayContent" *ngIf="!leftMenuService.errorCif">
		<mva10-c-select-field
			*ngIf="
				leftMenuService.addressSelectorLoaded &&
				leftMenuService.addressSelector &&
				leftMenuService.addressSelector.length > 1
			"
			[label]="leftMenuService.selectorDataDescription"
			[listItems]="leftMenuService.addressSelector"
			(clickEvent)="leftMenuService.setSiteSelectorClicked()"
			[preselectedValue]="leftMenuService.addressSelectorDefault"
		>
		</mva10-c-select-field>
	</div>
</ng-template>

<ng-template #skeleton>
	<div class="mva10-u--mt6x">
		<div class="skeleton vfac-side-menu__skeleton__button mva10-u--11/12"></div>

		<div>
			<div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
				<div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
				<div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
			</div>
			<div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
				<div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
				<div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
			</div>
			<div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
				<div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
				<div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #skeleton2>
	<div class="vfac-side-menu__skeleton__container">
		<div class="skeleton vfac-side-menu__skeleton__button mva10-u--11/12"></div>
		<div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
			<div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
			<div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
		</div>
	</div>
</ng-template>
