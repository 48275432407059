import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import * as constants from '../../shared/constants/defines';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { JourneyTaggingModel } from '../../shared/models/journey-tagging.model';
import { ErrorLocation, ErrorTaggingModel } from '../../shared/models/new-tagging-error.model';
import { InteractionTaggingModel, JsonFilesModel } from '../../shared/models/new-tagging-Interaction.model';
import { NewTaggingJsonModel } from '../../shared/models/new-tagging-json-model';
import { PageTaggingModel } from '../../shared/models/new-tagging-page.model';
import { StorageService } from './storage.service';

@Injectable()
export class NewTaggingHelperService {
	jsonFilesArray: JsonFilesModel[] = new Array<JsonFilesModel>();
	taggingBaseUrl: string = environment.imageBaseUrl;
	constructor(
		private taggingHelper: TaggingHelperService,
		private storageService: StorageService,
		private httpClient: HttpClient,
		private _ngZone: NgZone,
		public router: Router
	) {
		this.initHotJarLibrary();
	}

	/** new tagging interaction
	 * send the data with InteractionTaggingModel specifications
	 * send page variable of the interaction
	 * any more variable out of InteractionTaggingModel you need to send specificVariables
	 */
	public interaction(data: InteractionTaggingModel, pageVariables: PageTaggingModel, specificVariables?: any): void {
		if (data.journey) {
			const customerType: string = this.storageService.userProfile ? this.storageService.userProfile.customerType : '';
			data.journey.journey_category =
				this.taggingHelper.getUserType(customerType, ClientTypology) || ClientTypology.postpaid;
		}
		data.journey = { ...new JourneyTaggingModel(), ...(data.journey || {}) };
		// when track interaction dont send the journy object with the page Variable
		if (pageVariables && pageVariables['journey']) {
			delete pageVariables['journey'];
		}
		// if page has an otherVarible sent in custom_map object then it will be dedected and send here
		if (data['custom_map']) {
			specificVariables = { ...data['custom_map'], ...specificVariables };
			delete data['custom_map'];
		}
		const eventName: string = `${data.event.event_category}:${data.event.event_context}:${data.event.event_label}`;
		this.taggingHelper.track(eventName, data, pageVariables, specificVariables);
	}
	/** new tagging state send the data with PageTaggingModel specifications
	 * send page variable of the view
	 * any more variable out of PageTaggingModel you need to send specificVariables
	 * @param data tha page object in @type PageTaggingModel
	 * @param otherVariables any Variables out the @type PageTaggingModel
	 */
	public state(data: PageTaggingModel): void {
		let otherVariables: object;
		if (data.journey) {
			const customerType: string = this.storageService.userProfile ? this.storageService.userProfile.customerType : '';
			data.journey.journey_category = this.taggingHelper.getUserType(customerType, ClientTypology).toLowerCase();
		}
		data.journey = { ...new JourneyTaggingModel(), ...(data.journey || {}) };
		// if page has an otherVarible sent in custom_map object then it will be dedected and send here
		if (data['custom_map']) {
			otherVariables = data['custom_map'];
		}
		this.taggingHelper.view(data, otherVariables);
	}

	/** Exctrat the API URL path
	 */
	createErrorPath(errorItemURL: string): string {
		let errorPath: string = errorItemURL.replace(environment.middlewareBaseUrl, '');
		errorPath = errorPath.split('/').join('-').split('?')[0];
		return errorPath;
	}

	/** this function for maping the error to a concatenate it to an error
	 * item then send this concatenated list to error tagging
	 */
	public creatErrorItem(errorItem: HttpErrorResponse, clearPreviousErrList?: boolean): void {
		const item: ErrorTaggingModel = {
			error_type: this.taggingHelper.setErrorTypeValue(errorItem),
			error_location: ErrorLocation.back,
			error_code: errorItem.error?.ecode || 0,
			error_path: this.createErrorPath(errorItem.url || ''),
			error_origin: this.getPageURL(),
			error_module: errorItem.error?.transactionId,
			error_description: errorItem.error?.description || errorItem.error?.message || errorItem.message,
			http_status: errorItem.status,
		};
		const concatenatedErrorItem: string = this.parseErrorList(item);
		if (clearPreviousErrList) {
			this.taggingHelper.error_list = '';
		}
		this.taggingHelper.error_list =
			this.taggingHelper.error_list === ''
				? concatenatedErrorItem
				: `${this.taggingHelper.error_list},${concatenatedErrorItem}`;
	}
	getPageURL(): string {
		let pageroute: string = this.router.url;
		pageroute = pageroute.split('?')[0];
		return pageroute;
	}

	public parseErrorList(errorData: ErrorTaggingModel): string {
		return `${constants.taggingLiterals.error_list}`
			.replace('{error_type}', `${errorData.error_type}`)
			.replace('{error_location}', `${errorData.error_location}`)
			.replace('{error_code}', `${errorData.error_code}`)
			.replace('{error_path}', `${errorData.error_path}`)
			.replace('{error_origin}', `${errorData.error_origin}`)
			.replace('{error_module}', `${errorData.error_module}`)
			.replace('{http_status}', `${errorData.http_status}`)
			.replace('{error_description}', `${errorData.error_description}`);
	}

	/** get tagging json file and datasrc/app/products-and-services/landing/landing-bundles.model.ts
	 * @param pageKey {string} key of page object ex: page.pageTwo.three
	 * @param jsonFileName {string} name of the file json that have object of tagging
	 */

	getJsonTagging(jsonFileName: string, pageKey?: string): Observable<NewTaggingJsonModel> {
		const index: number = this.jsonFilesArray.findIndex((elem: JsonFilesModel) => elem.fileName === jsonFileName);
		let selectedTaggingJson: NewTaggingJsonModel;
		if (index === -1) {
			return this.httpClient.get(`${this.taggingBaseUrl}/config/analytics/${jsonFileName}.json`).pipe(
				map((res: NewTaggingJsonModel) => {
					selectedTaggingJson = res;
					this.jsonFilesArray.push({ fileName: jsonFileName, jsonDataObject: JSON.parse(JSON.stringify(res)) });
					if (pageKey) {
						selectedTaggingJson = selectedTaggingJson[pageKey];
					}
					return selectedTaggingJson;
				})
			);
		} else {
			selectedTaggingJson = JSON.parse(JSON.stringify(this.jsonFilesArray[index].jsonDataObject));
			if (pageKey) {
				selectedTaggingJson = selectedTaggingJson[pageKey];
			}
			return of(selectedTaggingJson);
		}
	}

	private initHotJarLibrary(): void {
		(window as any).new_hj = (event: string, eventName: string) => {
			this._ngZone.runOutsideAngular(() => {
				if ((window as any).hj) {
					(window as any).hj(event, eventName);
				} else {
					console.warn('HotJar lib not available yet');
				}
			});
		};
	}
}
