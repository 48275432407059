import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { StorageService } from '../../core/services/storage.service';
import { CONFIG, CSRFToken, LOCAL_STORAGE_KEYS } from '../../shared/constants/defines';
import * as Routes from '../../shared/constants/routes-config';
import { BillClarification } from '../../shared/models/bill-clarification.model';
import { Configurations } from '../../shared/models/configuration.model';
import { ProductConfiguration } from '../../shared/models/product-configuration.model';
import { SettingsResponse, TobiUserConfiguration } from '../../shared/models/settings-response.model';

/**
 * Configuration Service Used to load application Configuration from middleware
 * middleware URL is defined in the application enviroment settings.
 */
@Injectable()
export class ConfigurationService {
	public configuration: Configurations;
	isConfigLoaded: boolean = false;
	isConfigurationApiFailed: boolean = false;

	/**
	 * constructor inject http service in to it
	 */
	constructor(private http: HttpClient, private storage: StorageService, private app: AppService) {}
	/**
	 * Load function responsible for load configuration from server config path based on environment
	 */
	public load(): Observable<Configurations> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		// headers = headers.append('vf-target-stub', 'false');
		headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
		const config: { headers: HttpHeaders; observe: 'body' } = {
			headers: headers,
			observe: 'response' as 'body',
		};
		this.storage.removeFromLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		const newRefreshTokenDate: Date = new Date();
		if (this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)) {
			this.storage.setLocalStorage(
				LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP,
				newRefreshTokenDate.setDate(newRefreshTokenDate.getDate() - 1)
			);
		}

		return this.http
			.get(Routes.API_URLS.CONFIGURATIONS.CONFIG_GET.replace('{type}', 'All'), config)
			.pipe(
				finalize(() => {
					this.app.settingsSubject.next(true);
					this.isConfigLoaded = true;
				})
			)
			.pipe(
				map((response: HttpResponse<SettingsResponse>) => {
					const userCSRFToken: string = response.headers.get(CSRFToken);
					this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN, userCSRFToken);
					this.isConfigurationApiFailed = false;
					this.configuration = this.transformConfig(response.body);
					this.storage.setLocalStorage('settings', this.configuration);
					return this.configuration;
				}),
				catchError((error) => {
					this.isConfigurationApiFailed = true;
					if (this.storage.getLocalStorage('settings')) {
						this.configuration = this.storage.getLocalStorage('settings');
					} else {
						this.http.get('https://assets-es-sit1.dxlpreprod.local.vodafone.es/config/app-config.json').subscribe((res) => {
							this.configuration = res as Configurations;
							this.storage.setLocalStorage('settings', this.configuration);
							return res;
						});
					}
					return of(this.configuration);
				})
			);
	}
	/**
	 * Get Configuration variable loaded from load function
	 */
	public getConfiguration(): Configurations {
		return this.configuration;
	}
	/**
	 * Map the JSON object returned from the backend
	 * to Configuration model
	 */
	transformConfig(config: SettingsResponse): Configurations {
		const configurationModel: Configurations = new Configurations();

		this.setBasicConfigurations(configurationModel, config);
		this.setBackendAvailabilityFlags(configurationModel, config);
		this.setReviewBillingJourney(configurationModel, config);
		this.setAdditionalConfigurations(configurationModel, config);

		return configurationModel;
	}

	private setBasicConfigurations(configurationModel: Configurations, config: SettingsResponse): void {
		configurationModel.newAnonymousJourney = config.newAnonymousJourney || false;
		configurationModel.availabilityOfRMCA = !!config.availabilityOfRMCA;
		configurationModel.networkLogin = config.networkLogin || null;
		configurationModel.showBillsLink = config.showBillsLink || null;
		configurationModel.showOneNumberSSO = config.showOneNumberSSO || null;
		configurationModel.showSecureNetSSO = config.showSecureNetSSO || null;
		configurationModel.chatEnabled = config.chatEnabled || null;
		configurationModel.tobiBubble = config['tobiBubble'] || null;
		configurationModel.hideBillConfigurationToggles = config.hideBillConfigurationToggles || false;
		configurationModel.tobiChatUnAvailability = config.tobiChatUnAvailability;
		configurationModel.tobiUserConfiguration = [...this.transformTobiUserConfig(config)];
	}

	private setBackendAvailabilityFlags(configurationModel: Configurations, config: SettingsResponse): void {
		configurationModel.biztalkAvailabilityFlag = config.biztalkAvailabilityFlag !== false;
		configurationModel.paymentUnavailability = config.paymentUnavailability === true;
		configurationModel.billingFlag = config.billingFlag !== false;
		configurationModel.clarifyFlag = config.clarifyFlag !== false;
		configurationModel.genevaFlag = config.genevaFlag !== false;
		configurationModel.ocsFlag = config.ocsFlag !== false;
		configurationModel.topupNewPCI = config.topupNewPCI !== false;
		configurationModel.topupNewPCIMsisdnPattern = config.topupNewPCIMsisdnPattern || '';
		configurationModel.disablemiwifi = config.disableMiwifi !== false;
		configurationModel.showBillingEta = config.showBillingEta !== false;
		configurationModel.rsmAvailabilityForBillReview = config.rsmAvailabilityForBillReview;
		configurationModel.showBillstatus = config['showBillstatus'];
		configurationModel.showMovementsHistoryNewScreen = config.showMovementsHistoryNewScreen || false;
	}

	private setReviewBillingJourney(configurationModel: Configurations, config: SettingsResponse): void {
		configurationModel.reviewBillingJourney.pattern = config.reviewBillingJourney?.pattern || '';
		configurationModel.reviewBillingJourney.status = config.reviewBillingJourney?.status || '';
		configurationModel.reviewBillingJourney.validUsers = config.reviewBillingJourney?.validUsers || [];
		configurationModel.reviewBillingJourney.monthsToReview = config.reviewBillingJourney?.monthsToReview || 0;
		configurationModel.reviewBillingJourney.monthsToReviewTop = config.reviewBillingJourney?.monthsToReviewTop || 0;
	}

	private setAdditionalConfigurations(configurationModel: Configurations, config: SettingsResponse): void {
		configurationModel.billClarifications = [...this.transformBillClarificationConfig(config)];
		configurationModel.productConfiguration = [...this.transformProductConfig(config)];
		configurationModel.showEverythingIsOk = config.showEverythingIsOk || false;
		configurationModel.smartPayMSIDNPattern = config.smartPayMSIDNPattern;
		configurationModel.PurchaseDefaultJourney = config.newPrepagoLinesPurchaseDefaultJourney;
		configurationModel.isAppUnavailable = config.appAvailability === false;
		configurationModel.tvVodafoneAvailability = config.tvVodafoneAvailability || false;

		/** Availability of superwifi flow */
		configurationModel.showSuperWifi = !!config.showSuperWifi;
		configurationModel.storiesPosition = config.storiesPosition;
	}

	transformTobiUserConfig(config: SettingsResponse): TobiUserConfiguration[] {
		const configTobiModel: TobiUserConfiguration[] = [];
		const tobiUserConfiguration: TobiUserConfiguration[] = config.tobiUserConfiguration || [];
		tobiUserConfiguration.forEach((tobiUser) => {
			const tobiConfigurationUser: TobiUserConfiguration = new TobiUserConfiguration();
			tobiConfigurationUser.customerType = tobiUser.customerType;
			tobiConfigurationUser.profileType = tobiUser.profileType;
			configTobiModel.push(tobiConfigurationUser);
		});
		return configTobiModel;
	}

	transformBillClarificationConfig(config: SettingsResponse): BillClarification[] {
		const configBillClarificationModel: BillClarification[] = [];
		const billClarifications: BillClarification[] = config['billClarifications'] || [];
		billClarifications.forEach((billSetting) => {
			const billClarification: BillClarification = new BillClarification();
			billClarification.customerType = billSetting.customerType;
			billClarification.segments = billSetting.segments;
			billClarification.tariffCodes = billSetting.tariffCodes;
			configBillClarificationModel.push(billClarification);
		});
		return configBillClarificationModel;
	}

	transformProductConfig(config: SettingsResponse): ProductConfiguration[] {
		const configProductModel: ProductConfiguration[] = [];
		const productConfiguration: ProductConfiguration[] = config.productConfiguration || [];
		productConfiguration.forEach((productConfig) => {
			const productConfiguration: ProductConfiguration = new ProductConfiguration();
			productConfiguration.web = productConfig.web;
			productConfiguration.featureID = productConfig.featureID;
			productConfiguration.filterType = productConfig.filterType;
			productConfiguration.filterValue = productConfig.filterValue;
			productConfiguration.operator = productConfig.operator;
			productConfiguration.adobe = productConfig.adobe;
			configProductModel.push(productConfiguration);
		});
		return configProductModel;
	}
}
