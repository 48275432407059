import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Breakpoints, CardImageSelectorModel, Mva10SelectFieldModel, SelectorModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { config } from '../../../../config/pages-config';
import { tagging } from '../../../../config/tagging-config';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { MyAccountService } from '../../../my-account/my-account.service';
import {
	FOLDER_TRAY_FOR_YOU,
	FOLDER_TRAY_HISTORY,
	FOLDER_TRAY_KIT_DIGITAL,
	FOLDER_TRAY_ONE_PROFESSIONAL,
	TRAY_CONFIG_TRACKING,
} from '../../../shared/constants/archetype/trayConfigTracking';
import { NotificationType } from '../../../shared/enums/notification-type.enum';
import { ProductManagementEnum } from '../../../shared/enums/product-management.enum';
import { ServiceType } from '../../../shared/enums/serviceType.enum';
import { SideMenuItemType } from '../../../shared/enums/side-menu-item-type.enum';
import { SiteStatus } from '../../../shared/enums/siteStatus.enum';
import { CardSelectorExtendedModel } from '../../../shared/models/card-selector-extended.model';
import { eventCategory } from '../../../shared/models/new-tagging-Interaction.model';
import { NewTaggingJsonModel } from '../../../shared/models/new-tagging-json-model';
import { ProductManagementModel } from '../../../shared/models/product-management.model';
import { ServiceModel } from '../../../shared/models/service.model';
import { SideMenuItemModel } from '../../../shared/models/side-menu-item.model';
import { SideMenuModel } from '../../../shared/models/side-menu.model';
import { TaggingClickDataModel } from '../../../shared/models/tagging.model';
import { NotificationBadgeService } from '../../../shared/services/notification-badge.service';
import { SiteHandlingService } from '../../../shared/services/site-handling.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { StorageService } from './../../../core/services/storage.service';
import { SubscriptionService } from './../../../core/services/subscription.service';
import { LoginService } from './../../../login/login.service';
import {
	adaraValues,
	HttpRequestMethods,
	LeftMenuIconsName,
	MENU_ACTION_TYPE,
	SidemenuQuery,
	stringOfTrue,
} from './../../../shared/constants/defines';
import { NavigationType } from './../../../shared/enums/navigation-type.enum';
import { ProfileType } from './../../../shared/enums/profileType.enum';
import { SidemenuCategory } from './../../../shared/enums/sidemenu-category.enum';
import { CompanyService } from './../../../shared/services/company.service';
import { CustomerAccountService } from './../../../shared/services/customer-account.service';
import { SidemenuConfigService } from './../../../shared/services/sidemenu-config.service';
import { TrayConfigurationService } from './../../dashboard/services/tray-configuration.service';
import { ResizeService } from './resize.service';
@Injectable()
export class LeftMenuWs10Service {
	showLeftMenu = false;
	leftMenuData = new SideMenuModel();
	expanded: boolean;
	selectedOption: SideMenuItemType;
	public showSubmenu = false;
	clientsPQ = false;
	currentCif: any;
	currentAddress: any;
	addressSelector: Mva10SelectFieldModel[];
	addressSelectorDefault: string;
	addressSelectorLoaded = false;
	cifTabIdx = 0;
	productSelectorLoad = false;
	selectorDataButtonText: string;
	selectorDataDescription: string;
	errorCif = false;
	loaded = false;
	public fullViewPage: boolean = true;
	public itemsTablet: SideMenuItemModel[];
	public itemsDesktop: SideMenuItemModel[];
	private productSelectedSubject = new Subject<any>();
	private clickedButtonSubject = new Subject();
	private cifSelectorSubject: Subject<number> = new Subject<number>();
	private siteSelectorSubject = new Subject();
	startIndexSelectorCard: number;
	isSitePendingInstallation: boolean;
	isOpProducts: boolean = false;
	isProductDetail: boolean = false;
	shopSelectorButton: SideMenuItemModel;
	topUpButton: SideMenuItemModel;
	placeHolderButton: SideMenuItemModel;
	secondButton: SideMenuItemModel;
	thirdButton: SideMenuItemModel;
	public isPrepaidUser: boolean = null;
	hasMktpEntrypoint: boolean;
	isCurrentSiteActive: boolean;
	isMicroEnabledWeb: boolean;

	constructor(
		private menuService: SidemenuConfigService,
		private router: Router,
		private resizeService: ResizeService,
		private translateService: TranslateService,
		private tagging: TaggingHelperService,
		private newTagging: NewTaggingHelperService,
		private customerAccountService: CustomerAccountService,
		private storageService: StorageService,
		private companyService: CompanyService,
		private subscriptionService: SubscriptionService,
		private utils: UtilitiesService,
		private notificationBadge: NotificationBadgeService,
		private loginService: LoginService,
		private siteHandlingService: SiteHandlingService,
		private trayConfigurationService: TrayConfigurationService,
		private myAccountService: MyAccountService
	) {
		this.notificationBadge.getRealTimeCounter().subscribe((count): void => {
			this.itemsTablet = this.itemsTablet?.map((item) => {
				if (item.type === SideMenuItemType.MENU) {
					return { ...item, badgeText: count[NotificationType.INBOX] || '' };
				}
				if (item.type === SideMenuItemType.ACCOUNT) {
					return { ...item, badgeText: count[NotificationType.TOTAL] > 0 ? ' ' : '' };
				}
				return item;
			});
			this.itemsDesktop = this.itemsTablet;
			this.updateItems();
		});
	}

	get productSelected$(): Observable<any> {
		return this.productSelectedSubject.asObservable();
	}

	get clickedButton$(): Observable<any> {
		return this.clickedButtonSubject.asObservable();
	}

	get cifSelectorClicked$(): Observable<number> {
		return this.cifSelectorSubject.asObservable();
	}

	get siteSelectorClicked$(): Observable<any> {
		return this.siteSelectorSubject.asObservable();
	}

	initLeftMenu() {
		if (!this.loaded) {
			if (this.isPrepaidUser === null) {
				this.isPrepaidUser = this.subscriptionService.customerData?.currentService?.type === ServiceType.Prepaid;
			}

			this.isCurrentSiteActive = this.utils.getCurrentSiteStatus() === SiteStatus.Active;
			this.isMicroEnabledWeb =
				this.translateService.instant('v10.flows.screen.S4U.dashboardtile.RSMicroEnabled.web') === stringOfTrue;

			this.enableMenu();

			this.resizeService.onResize$.subscribe((size: Breakpoints) => {
				this.updateItems();
			});

			this.customerAccountService.productsUpdated$.subscribe((selectorData: any) => {
				this.initSelectorData();
			});

			this.translateService.get('v10.common.tray').subscribe((text) => {
				const menuButton = new SideMenuItemModel();
				menuButton.iconClassName = LeftMenuIconsName.menu;
				menuButton.text = text.lnk_menu;
				menuButton.type = SideMenuItemType.MENU;

				this.setSecondLeftMenuButton();
				this.setThirdLeftMenuButton();

				const productsButton = new SideMenuItemModel();
				productsButton.iconClassName = LeftMenuIconsName.products;
				productsButton.text = text.lnk_products;
				productsButton.type = SideMenuItemType.PRODUCTS;

				const accountButton = new SideMenuItemModel();
				accountButton.iconClassName = LeftMenuIconsName.myAccount;
				accountButton.text = text.lnk_account;
				accountButton.type = SideMenuItemType.ACCOUNT;

				let items;
				if (this.isPrepaidUser) {
					items = [menuButton, this.secondButton, this.thirdButton, productsButton, accountButton];
				} else {
					items = [menuButton, productsButton, accountButton];
				}

				this.itemsTablet = items;

				this.updateItems();
			});

			if (this.showLeftMenu) {
				this.initServiceSelector();
			}
			this.loaded = true;
		}
	}

	private enableMenu() {
		if (this.router.url !== config.login.route && this.router.url !== config.login.companyChooser.route) {
			this.showLeftMenu = true;
		} else {
			this.showLeftMenu = false;
		}
	}

	setCifSelectorClicked(cifTabIdx) {
		this.cifTabIdx = cifTabIdx;
		this.companyService.selectedCompanyId = this.companyService.CompanyList[cifTabIdx].id;

		if (this.router.url.split('?')[0] === config.dashboard.route) {
			this.cifSelectorSubject.next(cifTabIdx);
		} else {
			this.updateCifSelector(cifTabIdx);
			if (this.router.url.split('?')[0] !== config.ProductsAndServices.MainScreen.route) {
				this.router.navigate([config.dashboard.route]);
			}
		}
	}

	private updateCifSelector(tabIdx) {
		this.cifTabIdx = tabIdx;
		this.companyService.selectedCompanyId = this.companyService.CompanyList[tabIdx].id;
		if (tabIdx === undefined) {
			this.tagging.track(tagging.dashboard.retryCif.eventName, tagging.dashboard.retryCif.data);
		}

		if (this.companyService.selectedCompanyId !== this.customerAccountService.otherCIFs) {
			this.productSelectorLoad = false;
			this.addressSelectorLoaded = false;
			this.errorCif = false;
			this.subscriptionService.GetCompanyServices(this.companyService.selectedCompanyId).subscribe(
				(result) => {
					this.initServiceSelector();
					this.siteSelectorSubject.next(this.subscriptionService.customerData.currentService);
					this.tagging
						.encryptApi(this.subscriptionService.customerData.currentService.id)
						.pipe(
							finalize(() => {
								this.tagging.track(tagging.dashboard.selectCif.eventName, tagging.dashboard.selectCif.data);
							})
						)
						.subscribe();
				},
				(error) => {
					this.productSelectorLoad = true;
					this.setAddressSelector();
					this.errorCif = true;
					this.errorTagService(error);
				}
			);
		} else {
			this.loginService.siebleRedirection(
				this.subscriptionService.customerData.customerAccountsId,
				this.subscriptionService.customerData.currentService.id
			);
		}
	}

	private initServiceSelector() {
		this.addressSelectorLoaded = false;
		this.productSelectorLoad = false;

		if (!this.subscriptionService?.serviceListSite?.length && this.companyService.selectedCompanyId) {
			const companyId: string = this.companyService.selectedCompanyId;
			this.subscriptionService.GetDefaultServices(companyId && companyId.length ? companyId : null).subscribe(
				() => {
					this.initProducts();
				},
				() => {
					this.errorCif = true;
					this.addressSelectorLoaded = true;
					this.productSelectorLoad = true;
				}
			);
		} else {
			this.initProducts();
		}
	}

	private initProducts(): void {
		this.customerAccountService.isPendingInstallAPICalled = true;
		this.customerAccountService.getCustomerAccountsWS10(null, true).subscribe(
			(res) => {
				this.customerAccountService.initSelector(null, true);
				this.customerAccountService.isPendingInstallAPICalled = false;
				this.customerAccountService.PendingInstallDataLoaded.next(res);
			},
			() => {
				this.customerAccountService.isPendingInstallAPICalled = false;
				this.errorCif = true;
				this.addressSelectorLoaded = true;
				this.productSelectorLoad = true;
			}
		);
	}

	initSelectorData() {
		this.setSelectorDataText();
		this.setAddressSelector();
		this.initCifSelector();
		this.clientsPQ =
			this.storageService.userProfile.customerType.toLowerCase() !== ProfileType.EMPLOYEE.toLowerCase() &&
			this.storageService.userProfile.customerType.toLowerCase() !== ProfileType.SME.toLowerCase();
		this.productSelectorLoad = true;
		this.addressSelectorLoaded = true;
		this.errorCif = false;
	}

	updateAddress() {
		this.setSelectorDataText();
		this.currentAddress = this.addressSelector.find((field) => field.checked).name;
		this.productSelectorLoad = true;
		this.addressSelectorLoaded = true;
		this.errorCif = false;
	}

	private setSelectorDataText() {
		this.translateService.get('v10.common.tray.overlay_myproducts').subscribe((text) => {
			this.selectorDataDescription = text.selectaddress;
			this.selectorDataButtonText = this.customerAccountService.isSitePrepaid
				? text.products_services_button
				: text.mysubscriptions;
		});
	}

	private setAddressSelector(): void {
		this.addressSelector = [
			...this.customerAccountService.customerAccounts,
			...this.customerAccountService.customerAccountsPendingInstall,
		]
			.filter((item) => item.marketType !== adaraValues.CLIENT_ADARA)
			.map((item) => {
				const field: Mva10SelectFieldModel = new Mva10SelectFieldModel();
				field.name = item.address.formattedAddress ? item.address.formattedAddress : item.address.toString();
				field.value = item.id;
				field.checked = false;
				if (item.id === this.subscriptionService.customerData.customerAccountsId) {
					field.checked = true;
					this.addressSelectorDefault = field.value;
					this.currentAddress = field.name;
				}
				return field;
			});
	}

	private initCifSelector() {
		this.currentCif = this.companyService.selectedCompanyId;
		if (this.customerAccountService.selectorCIFData) {
			this.cifTabIdx = this.customerAccountService.selectorCIFData.findIndex((item) => item === this.currentCif);
		}
	}

	setPreSiteSelectorClicked(ind: number, gotoDistrub?: boolean): void {
		this.addressSelector.forEach((item: Mva10SelectFieldModel) => (item.checked = false));
		this.addressSelector[ind].checked = true;
		this.setSiteSelectorClicked(gotoDistrub);
	}

	setSiteSelectorClicked(gotoDistrub?: boolean): void {
		const id: string = this.addressSelector.find((selectField) => {
			return selectField.checked;
		}).value;

		if (this.customerAccountService.selectDefault !== id || !id) {
			const idSelected: string = id ? id : this.customerAccountService.selectDefault;
			this.productSelectorLoad = false;
			const _found: CardSelectorExtendedModel = this.customerAccountService.primaryList.find(
				(element) => element.id === idSelected
			);
			this.customerAccountService.selectDefault = _found ? _found.id : null;
			this.subscriptionService.customerData.customerAccountsId = this.customerAccountService.selectDefault;

			this.isSitePendingInstallation = this.customerAccountService.isPendingInstallationSiteStatus();

			this.subscriptionService.customerData.services = [];
			this.customerAccountService.isUpdating = true;

			if (this.isSitePendingInstallation) {
				this.productSelectorLoad = true;
				this.closeSubmenu();
			} else {
				this.subscriptionService.GetCustomerServices(idSelected).subscribe(
					(result) => {
						this.customerAccountService.isUpdating = false;
						this.subscriptionService.customerData.currentService = result[0];
						this.subscriptionService.customerSubject.next(this.subscriptionService.customerData.currentService);
						this.siteSelectorSubject.next(this.subscriptionService.customerData.currentService);
						this.customerAccountService.selectorData = new SelectorModel();
						this.customerAccountService.selectorData.cardImageSelectorList = [];
						this.customerAccountService.isSitePrepaid = true;
						this.subscriptionService.serviceListSite.forEach((item) => {
							const type: any = item.type;
							this.customerAccountService.checkSitePrepaid(type);
							this.customerAccountService.selectorData.cardImageSelectorList.push(
								this.customerAccountService.mapServiceSelector(item, type)
							);
						});
						this.customerAccountService.selectorDataWithSubscriptions =
							this.customerAccountService.getServicesListWithSubscriptions();
						this.startIndexSelectorCard = this.customerAccountService.selectorData.cardImageSelectorList.findIndex(
							(select) => select.checked === true
						);
						this.setSelectorDataText();
						this.updateAddress();
						this.customerAccountService.isUpdating = false;
						// update menu
						this.loaded = false;
						this.isPrepaidUser = this.subscriptionService.customerData?.currentService?.type === ServiceType.Prepaid;
						this.initLeftMenu();
					},
					(error) => {
						this.myAccountService.commitmentContractErrorHandling(error, HttpRequestMethods.get);
						this.customerAccountService.isUpdating = false;
						this.productSelectorLoad = true;
					}
				);
			}
		}
		this.gotoToProducts(gotoDistrub);
	}

	gotoToProducts(gotoDistrub?: boolean) {
		this.closeSubmenu();
		if (gotoDistrub) {
			this.router.navigate([config.ProductsAndServices.MainScreen.route]);
		} else {
			this.router.navigate([config.ProductsAndServices.route]);
		}
	}

	updateDashboardSite() {
		const selectedIdx = this.addressSelector.findIndex((selectField) => {
			return selectField.checked;
		});
		this.currentAddress = this.addressSelector[selectedIdx].name;
		const siteClicked = this.customerAccountService.primaryList[selectedIdx];
		this.siteSelectorSubject.next(siteClicked.id);
	}

	productsButtonClicked() {
		this.closeSubmenu();
		this.clickedButtonSubject.next();
	}

	productSelected(productId: string): void {
		this.closeSubmenu();
		this.isOpProducts = false;

		this.resetTrayButtonsWhenProductSelect(
			this.subscriptionService.customerData.currentService,
			this.subscriptionService.customerData.services.find((srv) => srv.id === productId)
		);

		if (productId === ProductManagementEnum.online_subscriptions) {
			const product: ProductManagementModel = this.translateService.instant(
				'v10.dashboard.gestion.list.online_subscriptions'
			);

			const eventName: string = tagging.dashboard.subscriptionOnlineTrayClick.eventName;
			const data: TaggingClickDataModel = Object.assign({}, tagging.dashboard.subscriptionOnlineTrayClick.data);
			this.tagging.track(eventName, data);

			this.router.navigate([product.url_pm.split('?')[0]], {
				queryParams: this.utils.getRouteParams(product.url_pm),
			});
		} else if (productId === ProductManagementEnum.business) {
			this.isOpProducts = true;
			let data: TaggingClickDataModel;
			const title: CardImageSelectorModel = this.customerAccountService.selectorData.cardImageSelectorList.find(
				(element) => element.id === ProductManagementEnum.business
			);
			const eventName: string = tagging.dashboard.selectorCardBusinessClicked.eventName.replace(
				'product',
				`${title.title} ${title.description}`
			);
			data = { ...tagging.dashboard.selectorCardBusinessClicked.data };
			data.event_label = `${title.title} ${title.description}`;
			this.tagging.track(eventName, data);
			this.router.navigate([config.ProductsAndServices.MainScreen.route]);
		} else if (this.router.url.split('?')[0] !== config.dashboard.route) {
			const currentService: ServiceModel = this.subscriptionService.serviceListSite.find(
				(service) => service.id === productId
			);
			if (currentService) {
				this.subscriptionService.customerData.currentService = currentService;
				this.changeProduct(productId);
				this.router.navigate([config.ProductsAndServices.MainScreen.route]);
			}
		} else {
			const service: ServiceModel = this.subscriptionService.customerData.services.find((srv) => srv.id === productId);
			if (this.subscriptionService.customerData.currentService.id !== service.id) {
				this.subscriptionService.customerData.currentService = service;
			}
			this.fullViewPage = false;
			this.router.navigate([config.ProductsAndServices.route]);
		}
	}

	changeProduct(product: string): void {
		if (this.customerAccountService.selectorData) {
			this.customerAccountService.selectorData.cardImageSelectorList.forEach((item) => {
				item.checked = false;
				if (item.id === product) {
					item.checked = true;
				}
			});
		}
	}

	logoutClick() {
		this.closeSubmenu();
		this.menuService.leftMenuItemClick({
			navigationType: NavigationType.Internal,
			itemText: '',
			locked: false,
			iconClassName: '',
			actionType: MENU_ACTION_TYPE.Command,
			command: SidemenuQuery.logout,
			category: SidemenuCategory.General,
		});
	}

	leftMenuItemClick(item) {
		this.selectedOption = item.type;
		if (item.type === SideMenuItemType.ACCOUNT) {
			this.closeSubmenu();
			this.router.navigate([config.myAccount.menu.route]);
		} else if (item.type === SideMenuItemType.PRODUCTS) {
			this.newTagging
				.getJsonTagging('products-and-services/products-and-services-dashboard')
				.subscribe((data: NewTaggingJsonModel) => {
					data.page.page_name = 'dashboard:home';
					this.newTagging.interaction(data.eventList.click_on_tray, data.page);
				});
			if (this.addressSelector.length > 1 || this.customerAccountService.selectorCIFData.length > 1) {
				this.showSubmenu = true;
				this.expanded = true;
			} else {
				this.closeSubmenu();
				this.router.navigate([config.ProductsAndServices.route]);
			}
		} else if (item.type === SideMenuItemType.MENU || item.type === SideMenuItemType.ACCOUNT) {
			this.showSubmenu = true;
			this.expanded = true;
		} else if (item.type === SideMenuItemType.SHOP) {
			this.buttonShopClick();
		} else {
			this.closeSubmenu();
			this.buttonClicked(item);
			this.router.navigate([item.route]);
		}
	}

	onClickSubmenuItem(item) {
		this.closeSubmenu();
		this.fullViewPage = !item.redirectTo?.includes(config.ProductsAndServices.landing.route);
		this.menuService.leftMenuItemClick(item);
	}

	closeSubmenu() {
		this.showSubmenu = false;
		this.expanded = false;
	}

	public updateItems(): void {
		this.leftMenuData.items = this.itemsTablet;
	}

	buttonClicked(button: SideMenuItemModel): void {
		if (button.type === SideMenuItemType.FORYOU) {
			this.trayConfigurationService.trayInteractionTagging(
				FOLDER_TRAY_FOR_YOU,
				TRAY_CONFIG_TRACKING.EVENTS.CLICK_FOR_YOU
			);
		} else if (button.type === SideMenuItemType.HISTORY) {
			this.trayConfigurationService.trayInteractionTagging(
				FOLDER_TRAY_HISTORY,
				TRAY_CONFIG_TRACKING.EVENTS.CLICK_HISTORY
			);
		} else if (button.type === SideMenuItemType.KITDIGITAL) {
			this.trayConfigurationService.trayInteractionTagging(
				FOLDER_TRAY_KIT_DIGITAL,
				TRAY_CONFIG_TRACKING.EVENTS.CLICK_KIT_DIGITAL
			);
		} else if (button.type === SideMenuItemType.ONEPROFESSIONAL) {
			this.trayConfigurationService.trayInteractionTagging(
				FOLDER_TRAY_ONE_PROFESSIONAL,
				TRAY_CONFIG_TRACKING.EVENTS.CLICK_ONE_PROFESSIONAL
			);
		} else if (button.route === config.PurchasedProducts.route) {
			const eventName = tagging.dashboard.leftMenuMyServicesClick.eventName;
			const data = tagging.dashboard.leftMenuMyServicesClick.data;
			this.tagging.track(eventName, data);
		} else if (button.route === config.myAccount.route) {
			const eventName = tagging.dashboard.leftMenuMyAccountClick.eventName;
			const data = tagging.dashboard.leftMenuMyAccountClick.data;
			this.tagging.track(eventName, data);
		}
	}

	clearMenu() {
		this.loaded = false;
		this.showLeftMenu = false;
		this.closeSubmenu();
		this.hasMktpEntrypoint = null;
		this.isPrepaidUser = null;
	}

	buttonShopClick(): void {
		this.trayConfigurationService.navigateToShop();
	}

	resetTrayButtonsWhenProductSelect(previousService: ServiceModel, selectedService?: ServiceModel): void {
		if (previousService?.type !== selectedService?.type) {
			this.isPrepaidUser = selectedService?.type === ServiceType.Prepaid;
			this.loaded = false;
			this.initLeftMenu();
		}
	}

	setSecondLeftMenuButton(resetTrayButton?: boolean): void {
		this.secondButton = new SideMenuItemModel();
		if (this.isPrepaidUser) {
			this.secondButton.iconClassName = LeftMenuIconsName.topUps;
			this.secondButton.text = this.translateService.instant('v10.common.tray.lnk_top_up');
			this.secondButton.route = config.TopUp.topUpPrepaidservice.route;
			this.secondButton.type = SideMenuItemType.TOPUP;
		}
	}

	setThirdLeftMenuButton(resetTrayButton?: boolean): void {
		this.thirdButton = new SideMenuItemModel();
		if (this.isPrepaidUser) {
			this.thirdButton.iconClassName = LeftMenuIconsName.history;
			this.thirdButton.text = this.translateService.instant('v10.common.tray.lnk_history');
			this.thirdButton.route = config.TopUp.topUpMovementsHistory.route;
			this.thirdButton.type = SideMenuItemType.HISTORY;
		}
	}

	replaceButtonsOnServiceTypeChange(index: number, button: SideMenuItemModel): void {
		this.itemsTablet[index] = button;
		this.itemsDesktop[index] = button;
	}

	errorTagService(error: HttpErrorResponse) {
		this.newTagging
			.getJsonTagging('products-and-services/products-and-services-dashboard-select')
			.subscribe((data: NewTaggingJsonModel) => {
				if (this.router.url.includes(config.ProductsAndServices.name)) {
					data.page.page_name = 'dashboard:mis productos:servicios:selector de sede';
				}
				data.eventList.error_sede.event.event_category = this.isTecnicalError(error.error.errorCode)
					? eventCategory.errorT
					: eventCategory.errorF;
				data.eventList.error_sede.event.event_context = this.isTecnicalError(error.error.errorCode)
					? `ec:${error.status}`
					: `eh:${error.status}`;
				data.eventList.error_sede.event.event_label = 'selecciona una direccion';
				let dat = {};
				dat['&&events'] = this.isTecnicalError(error.error.errorCode) ? 'event114' : 'event115';
				dat['error_list'] = `
				et=${this.isTecnicalError(error.error.errorCode) ? eventCategory.errorT : eventCategory.errorF}|
				el=front|
				ec=${error.error.errorCode}|
				em=component|
				eh=${error.error.errorCode}|
				ed=${error.statusText}|
				ep=${error.url}|
				eo=modulo de leftmenu|
				`;
				this.newTagging.interaction({ ...data.eventList.error_sede, ...dat }, data.page);
			});
	}
	isTecnicalError(code: string): boolean {
		return parseInt(code) >= 400;
	}
}
