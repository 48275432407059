<div class="vfac-submenu-products vfes-5/6">
	<ng-container *ngIf="!leftMenuService.errorCif">
		<p class="vfac-submenu-products__title mva10-u--pb3x">
			{{ 'v10.common.tray.overlay_myproducts.myproducts' | translate }}
		</p>
		<p class="vfac-submenu-products__subtitle">{{ 'v10.myAccountMultisiteSelector.title' | translate }}</p>
	</ng-container>

	<mva10-c-tabs-simple
		*ngIf="okCif"
		[dataSelectTab]="leftMenuService.cifTabIdx"
		[styleInline]="{ 'margin-bottom': '24px' }"
		[ngClass]="'mva10-u--mr2x'"
		(mva10OnClick)="clickOnCif($event)"
		(keyup)="handlerClinkOnCif($event)"
	>
		<mva10-c-tab *ngFor="let tab of customerAccountService.selectorCIFData; let i = index" [title]="tab">
			<ng-container *ngIf="leftMenuService.addressSelectorLoaded; then content"> </ng-container>
		</mva10-c-tab>
	</mva10-c-tabs-simple>

	<ng-container
		*ngIf="!customerAccountService.selectorCIFData || customerAccountService.selectorCIFData.length <= 1"
		[ngTemplateOutlet]="content"
	>
	</ng-container>

	<ng-container *ngIf="leftMenuService.errorCif" [ngTemplateOutlet]="errorCifSection"> </ng-container>

	<ng-container *ngIf="!leftMenuService.addressSelectorLoaded" [ngTemplateOutlet]="skeleton"> </ng-container>
</div>

<ng-template #content>
	<div
		class="vfac-submenu-products__products scrollbar-hidden mva10-u--pt2x vfes-5/6"
		*ngIf="!leftMenuService.errorCif"
	>
		<div *ngFor="let item of leftMenuService.addressSelector; let i = index" class="mva10-u--mb4x">
			<mva10-c-card
				[ngClass]="item.checked ? 'sel' : ''"
				[modifier]="'line'"
				[id]="item.value"
				[selected]="item.checked"
				(click)="selectClick(i, $event)"
				(keyup)="handlerSelectClick($event)"
			>
				<p class="card_name">{{ item.name }}</p>
				<p class="card_id">id : {{ item.value }}</p>
			</mva10-c-card>
		</div>
		<div *ngIf="!telcoFilter">
			<div *ngIf="customerAccountService.hasAdaraSite; showProducts">
				<div *ngFor="let item of customerAccountService.adaraSelectorData?.cardImageSelectorList">
					<vfac-card-product
						[svg]="'#icon-idea-or-innovation-mid'"
						[title]="item.title"
						[description]="item.description"
						[checked]="item.checked"
						[colorBg]="item.backgroundColor"
						[borderColor]="item.borderColor"
						extraClass="mva10-u--mb2x"
						[modifier]="this.appService.checkYuUser() ? 'border-gradient-yu' : 'line'"
					>
					</vfac-card-product>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #skeleton>
	<div>
		<div class="skeleton vfac-submenu-products__skeleton__button mva10-u--1/2"></div>

		<div>
			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>

			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>

			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #errorCifSection>
	<div class="error">
		<mva10-c-icon
			class="icon"
			[iconId]="'icon-warning-mid'"
			[title]="'ver todos mis prodcutos'"
			[size]="5"
		></mva10-c-icon>
		<p class="strong">{{ 'v10.common.literals.msg.error.ups' | translate }}</p>
		<p>{{ 'v10.common.literals.msg.error.could_not_getdata' | translate }}</p>
		<mva10-c-link-with-icon [size]="2" (click)="clickOnCif($event)" (keyup)="handlerClinkOnCif($event)">
			{{ 'v10.common.literals.retry_C' | translate }}
		</mva10-c-link-with-icon>
	</div>
</ng-template>
