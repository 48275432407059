<div class="chat-pass row change-padding cont-height">
	<div class="col-xs-12">
		<div class="reamin-usage row change-padding">
			<div class="remain col-xs-7">
				<ng-container *ngIf="!isUnlimited">
					<span class="bundle-name-lines">
						<mva10-c-icon [size]="5" [iconId]="'icon-mail'" style="margin: -7px 18px 0 0"> </mva10-c-icon>
						<ng-container>
							<span *ngIf="!Bundle.title" class="mva10-u--pl2x"> {{ Bundle.Name }}&nbsp;</span>
							<span *ngIf="Bundle.title" class="mva10-u--pl-2x"> {{ Bundle.title }}&nbsp;</span>
						</ng-container>
					</span>
					<p class="call-price active-unlimated">
						<span
							*ngIf="parent.tariffService.Tariff.Yu && parent.isBenifitsActive && parent.isPrePaid"
							class="active-package"
						>
							{{ 'productsServices.itemsList.psYuBenefitsSms.body' | translate }}
						</span>
					</p>
				</ng-container>
				<ng-container *ngIf="isUnlimited">
					<mva10-c-icon [size]="5" [iconId]="'icon-mail'"> </mva10-c-icon>
					<span class="normalFont"> {{ 'productsServices.rgk.item.sms.title' | translate }}</span>
				</ng-container>
			</div>
			<ng-container *ngIf="!isUnlimited">
				<div class="col-xs-5 usage" *ngIf="!Bundle.IsUnLimited">
					<span> {{ Bundle.titleLimit }} {{ Bundle.titleRate }} </span>
				</div>
				<div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
					<span> {{ 'productsServices.rgk.item.sms.description' | translate }} </span>
				</div>
			</ng-container>
			<ng-container *ngIf="isUnlimited">
				<div class="col-xs-5 usageDestiny">
					<span> {{ 'productsServices.rgk.item.sms.description' | translate }} </span>
				</div>
			</ng-container>
		</div>
		<div class="call-info" *ngIf="Bundle.PayPerUse && Bundle.CallSetup && !parent.isPrePaid">
			<!-- pay per use b true w mintues -->
			<p class="call-price">
				<span class="package"> + {{ 'productsServices.prepaidPlan.itemsList.planEstablish.body' | translate }} </span>
			</p>
		</div>
	</div>
</div>
