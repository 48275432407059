import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { logisticStatus, messageFromIkki, myOrders2, subOrders, tabsMyOrders } from '../../../constants/defines';
import { OrderFlow } from '../../../enums/order-flow.enum';
import { StatePedido, orderTypes } from '../../../enums/order-type.enum';
import { constants } from '../../../models/constants';
import { Paso } from '../../../models/detalleSeguimientoPedido.model';
import { environmentMock } from '../../../models/environmentMock';
import { EventMessage } from '../../../models/eventMessage.model';
import { OrdenObtenerPedidos } from '../../../models/obtenerPedidos.model';
import { orderPedido } from '../../../models/orderPedido.model';
import { SlidService } from '../../my-orders/services/slid.service';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	public isIkkiWeb: boolean;
	public stepFlow: string;
	public flowNebaTV: string;
	public motiveSupport: string;
	public flujoTipo: string;
	public flujoPaso: string;
	public motivoAux: string;
	constructor(private translate: TranslateService, private injector: Injector) {}

	isAndroidApp(): boolean {
		return typeof window.AndroidApp === 'object';
	}

	isIOSApp(): boolean {
		return typeof window.webkit === 'object' && typeof window.webkit.messageHandlers.IOSApp === 'object';
	}

	isIkki(): boolean {
		return this.isAndroidApp() || this.isIOSApp() || this.isIkkiWeb;
	}

	isMobileSize(): boolean {
		const isWebMobileOrTablet: boolean = (this.isIkkiWeb || !environmentMock.baseUrl) && window.innerWidth < 768;
		return this.isAndroidApp() || this.isIOSApp() || isWebMobileOrTablet;
	}

	isSafari(): boolean {
		const ua: string = window.navigator.userAgent.toLowerCase();
		return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
	}

	isIphone(): boolean {
		return !!window.navigator.userAgent.match(/iPhone/i);
	}

	isWeb(): boolean {
		return !this.isAndroidApp() && !this.isIOSApp();
	}

	handleReceivedMessageFromIkki(receivedData?: string): void {
		try {
			const parsedData: EventMessage = JSON.parse(receivedData);
			if (parsedData.action === messageFromIkki.action) {
				if (+parsedData.statusCode === 200) {
					this.injector.get(SlidService).$routerSlidSubject.next(parsedData);
				} else {
					this.injector.get(SlidService).$routerSlidSubject.error(parsedData);
				}
			}
		} catch (error) {
			console.error(messageFromIkki.error, error);
		}
	}

	postMessageToParent(message: string, targetOrigin?: string): boolean {
		targetOrigin = targetOrigin || '*';
		let result: boolean = false;
		try {
			if (this.isAndroidApp()) {
				result = window.AndroidApp.postMessage(message);
			} else if (this.isIOSApp()) {
				result = window.webkit.messageHandlers.IOSApp.postMessage(message);
			} else {
				const urlParent: string = window.parent.location.href;
				if (urlParent.includes(myOrders2.myorders)) {
					window.postMessage(message, targetOrigin);
				} else {
					window.parent.postMessage(message, targetOrigin);
				}
				result = true;
			}
		} catch (err) {
			console.error('Error enviando mensaje desde iframe a la aplicacion padre.', err);
		}
		return result;
	}

	/**
	 * Returns date in spanish format. '24 Ago 2021'
	 * @param {Date | string | number} date Valid date format compatible with 'new Date(date)'
	 * @param {'long' | 'short'} [monthFormat] Defaults to 'short'. Value 'long' example: '24 Agosto 2021'
	 * @param {'long' | 'short' | 'letter'} [dateNameFormat] Adds date name. 'Miércoles 8 Sep 2021'
	 * @param {boolean} [removeYear] Removes year. '8 Sep'
	 * @param {boolean} [addNexusWord] Adds nexus word before month and year. '8 de Sep de 2021'
	 * @param {boolean} [removeYearOnlyIfCurrentYear] Removes the year only if different from current year. Param 'removeYear' must be false
	 */
	getFormattedDate(
		date?: Date | string | number,
		monthFormat?: 'long' | 'short',
		dateNameFormat?: string,
		removeYear?: boolean,
		addNexusWord?: boolean,
		removeYearOnlyIfCurrentYear?: boolean
	): string {
		date = date ? new Date(date) : new Date();
		monthFormat = constants.months[monthFormat] ? monthFormat : 'short';
		const dia: number = date.getDay() - 1 >= 0 ? date.getDay() - 1 : 6;
		let year: string;
		if (
			removeYear === true ||
			(removeYearOnlyIfCurrentYear && date.getFullYear() === new Date().getFullYear()) ||
			!addNexusWord
		) {
			year = '';
		} else {
			year = 'de';
		}
		return (
			(dateNameFormat ? constants.days[dateNameFormat][dia] + ' ' : '') +
			date.getDate() +
			(addNexusWord ? ' de' : '') +
			' ' +
			constants.months[monthFormat][date.getMonth()] +
			(year + ' ' + date.getFullYear())
		);
	}

	getProductsNames(name: string): string[] {
		return name.split('+');
	}

	getOrderProducts(name: string): string[] {
		const flatName: string = name.toLowerCase();
		const types: string[] = ['movil', 'fibra', 'tv'];
		return types.filter((type: string) => {
			return flatName.includes(type);
		});
	}

	getTypeByComercialName(name: string): string {
		const includedTypes: string[] = this.getOrderProducts(name);
		return includedTypes.length > 1 ? 'paquete' : includedTypes[0] || 'pedido';
	}

	getDetailsByComercialName(type: string) {
		const tipos: string[] = ['pedido', 'fibra', 'tv', 'movil', 'fijo', 'paquete', 'devolucion'];
		const orderType: string = tipos[type] || 'pedido';
		const colors: { [key: string]: string } = {
			movil: 'primary',
			paquete: 'primary',
			fibra: 'violet',
			fijo: 'purple',
			pedido: 'primary',
			adsl: 'violet',
			tv: 'cyan',
		};
		return {
			color: colors[orderType] || 'primary',
			icon: constants.icons[orderType || 'pedido'],
		};
	}

	normalize(str: string): string {
		if (!str) {
			return str;
		}
		const from: string = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüû',
			to: string = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuu',
			mapping: Record<string, string> = {};
		for (let i: number = 0, j: number = from.length; i < j; i++) {
			mapping[from.charAt(i)] = to.charAt(i);
		}
		const ret: string[] = [];
		for (let i: number = 0, j: number = str.length; i < j; i++) {
			const c: string = str.charAt(i);
			if (Object.prototype.hasOwnProperty.call(mapping, str.charAt(i))) {
				ret.push(mapping[c]);
			} else {
				ret.push(c);
			}
		}
		return ret.join('');
	}

	getStringOrdersFlux(orders: OrdenObtenerPedidos[] | string, isMainOrders?: boolean): string {
		let concatOrdersFlux: string = '';
		if (isMainOrders) return 'existe pedidos';
		if (orders.length) {
			if (Array.isArray(orders)) {
				concatOrdersFlux = this.getStringOrdersFluxRefact(orders, concatOrdersFlux);
			} else {
				if (concatOrdersFlux) {
					concatOrdersFlux = `${concatOrdersFlux}-generico`;
				} else {
					concatOrdersFlux = 'generico';
				}
			}
		} else {
			concatOrdersFlux = 'ninguno';
		}
		return concatOrdersFlux;
	}
	public getStringOrdersFluxRefact(orders, concatOrdersFlux: string): string {
		orders.forEach((order) => {
			if (order.nombreFlujo) {
				const orderFlux: OrderFlow = order.nombreFlujo;
				if (concatOrdersFlux) {
					concatOrdersFlux = `${concatOrdersFlux}-${orderFlux}`;
				} else {
					concatOrdersFlux = orderFlux;
				}
			} else {
				concatOrdersFlux = this.getStringOrdersFluxRefact2(concatOrdersFlux);
			}
		});
		return concatOrdersFlux;
	}
	public getStringOrdersFluxRefact2(concatOrdersFlux: string): string {
		return concatOrdersFlux ? `${concatOrdersFlux}-generico` : 'generico';
	}

	handleTituloResponseBox(isCambioCita: boolean, isPort: boolean, isChange: boolean, showError: boolean): string {
		let tituloMsg: string = '';
		if (isCambioCita) {
			if (showError) {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.changeErrorTitle');
			} else {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.changeSuccessTitle');
			}
		} else if (isPort) {
			if (showError) {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.relaunchPortaErrorTitle');
			} else {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.relaunchPortaOkTitle');
			}
		} else if (isChange) {
			if (showError) {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.relaunchPortaErrorTitle');
			} else {
				tituloMsg = this.translate.instant('v10.myOrder.responseBox.relaunchPortaChangeOkTitle');
			}
		} else {
			tituloMsg = this.handleTituloResponseBoxRefact(tituloMsg, showError);
		}
		return tituloMsg;
	}
	public handleTituloResponseBoxRefact(tituloMsg: string, showError: boolean): string {
		if (showError) {
			tituloMsg = this.translate.instant('v10.myOrder.responseBox.errorTitle');
		} else {
			tituloMsg = this.translate.instant('v10.myOrder.responseBox.successTitle');
		}
		return tituloMsg;
	}

	getShape(shape: string): string {
		return constants.svgIcons[shape];
	}

	getIcons(code: string): string {
		return constants.icons[code];
	}

	noDups(pasos: Paso[], paso: Paso, step: string, selectedOrderData?: OrdenObtenerPedidos): boolean {
		if (selectedOrderData?.statePedido === StatePedido.RETURNS) {
			return this.noDupsReturnOrders(paso, selectedOrderData);
		}
		const currentStep: number = parseInt(step);
		if (+currentStep / 10 < 1 || +paso.pasoOrden / 10 !== +currentStep / 10) {
			return +paso.pasoOrden / 10 < 1 && +paso.pasoOrden !== Math.floor(+currentStep / 10);
		}
		const pasosExtra: Paso[] = pasos.filter((step: Paso) => {
			return +step.pasoOrden / 10 > 1;
		});
		return !pasosExtra
			.map((step: Paso) => {
				return Math.floor(+step.pasoOrden / 10);
			})
			.includes(+paso.pasoOrden);
	}

	public noDupsReturnOrders(paso: Paso, selectedOrderData: OrdenObtenerPedidos): boolean {
		if (
			!logisticStatus.notStarted.includes(selectedOrderData.returnStatus) ||
			logisticStatus.accepted.includes(selectedOrderData.returnStatus) ||
			logisticStatus.ongoingReimbursement.includes(selectedOrderData.returnStatus)
		) {
			return logisticStatus.firstFlow.includes(paso.pasoOrden);
		} else if (logisticStatus.refused.includes(selectedOrderData.returnStatus)) {
			return logisticStatus.secondFlow.includes(paso.pasoOrden);
		} else if (logisticStatus.undelivered.includes(selectedOrderData.returnStatus)) {
			return logisticStatus.thirdFlow.includes(paso.pasoOrden);
		}
	}

	getTitleFecha(selectedType: string): string {
		let text: string;
		if (selectedType === orderTypes.vigentes) {
			text = 'Fecha de solicitud';
		} else if (selectedType === orderTypes.completados) {
			text = 'Fecha de finalización';
		} else if (selectedType === orderTypes.cancelados) {
			text = 'Fecha de cierre';
		}
		return text;
	}

	getUniqueValues<T>(array: T[], prop: string, prop2: string): orderPedido[] {
		return array
			.map((item: T): orderPedido => {
				const order: orderPedido = {
					idPedido: item[prop] ?? item[prop2],
					numOrder: item[prop2],
					delivered: item[subOrders.isDelivered],
				};
				return order;
			})
			.filter((item: orderPedido, index: number, self: orderPedido[]) => {
				return self.indexOf(item) === index;
			});
	}

	openAutoiChat(orderId: string, tipoFlujo?: string, pasoFlujo?: string, motivo?: string): void {
		if (tipoFlujo) this.flujoTipo = tipoFlujo;
		if (pasoFlujo) this.flujoPaso = pasoFlujo;
		if (motivo) this.motivoAux = motivo;
		this.postMessageToParent(
			JSON.stringify({
				action: 'help',
				message: 'open-chat',
				chat_id: 'autoi',
				order_id: orderId,
				tipo_flujo: this.flujoTipo,
				paso_flujo: this.flujoPaso,
				motivo_soporte: this.motivoAux,
			})
		);
	}

	// Fix ajimenar for WhatsApp
	public formatDateError(date: string): string {
		const day: string = date.substring(0, 2);
		const month: string = date.substring(3, 5);
		const year: string = date.substring(6, 10);
		const time: string = date.substring(11, 20);
		return `${year}${tabsMyOrders.guion}${month}${tabsMyOrders.guion}${day}${tabsMyOrders.letterT}${time}${tabsMyOrders.letterZ}`;
	}
}
